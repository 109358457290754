import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import { ISetToDefaultFormInitValues } from 'typescript/interfaces/deals'
import TextField from '@material-ui/core/TextField'
import VendorAutoComplete from 'components/VendorAutoComplete'
import Button from 'UI/Button/ButtonWithPreloader'
import { schemaSetDefault } from 'components/SetDefaultForm/schema'
import { IVendorListLightItem } from 'typescript/interfaces/vendor'

interface ISetDefaultFormProps {
  isDefault: boolean
  initValues?: ISetToDefaultFormInitValues
  submit: (values: ISetToDefaultFormInitValues) => void
  isLoading?: boolean
  cancel: () => void
}

const defaultInitValues: ISetToDefaultFormInitValues = {
  vendorIds: undefined,
  defaultFee: '',
}

const SetDefaultForm: FC<ISetDefaultFormProps> = ({ isDefault, initValues = defaultInitValues, submit, isLoading, cancel }) => {
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik<ISetToDefaultFormInitValues>({
    initialValues: initValues,
    validationSchema: schemaSetDefault,
    onSubmit: submit,
  })
  return (
    <Box width={240} maxWidth="100%">
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h2" color="textSecondary">
            {isDefault ? 'Update Default' : 'Set to Default'}
          </Typography>
        </Box>
        <Box mt="2rem">
          <VendorAutoComplete
            multiple
            vendorId={values.vendorIds}
            error={Boolean(touched.vendorIds && errors.vendorIds)}
            helperText={touched.vendorIds && errors.vendorIds}
            onChange={(e, val) => {
              if (val)
                setFieldValue(
                  'vendorIds',
                  val.map((v: IVendorListLightItem) => v.id),
                )
              else {
                setFieldValue('vendorIds', undefined)
              }
            }}
          />
        </Box>
        <Box mt="2rem">
          <TextField
            label="Default Fee"
            variant="outlined"
            fullWidth
            name="defaultFee"
            onChange={handleChange}
            value={values.defaultFee}
            error={Boolean(touched.defaultFee && errors.defaultFee)}
            helperText={touched.defaultFee && errors.defaultFee}
          />
        </Box>
        <Box mt="2rem" display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="contained" onClick={() => cancel()}>
            Cancel
          </Button>
          <Box ml="2rem">
            <Button loading={isLoading} type="submit" color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default SetDefaultForm
