import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper/Paper'
import IconButton from '@material-ui/core/IconButton'
import cn from 'classnames'
import { ReactComponent as ArrowLeftIcon, ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { TableFunderLenders, useAssignLenders } from 'hooks/useAssignLenders'
import ModalComponent from 'UI/Modal'
import AssignLenderForm from 'components/AssignLenderForm'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { DEAL_INFO_URL, NEW_DEAL_URL } from 'constants/routes'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { createLender, editLender, getDeal, getFunderLenders } from 'services/deals'
import { DealsStatus } from 'typescript/enums/deals'
import { CompanyType } from 'typescript/interfaces/companies'
import { useAssignLendersStyle } from 'containers/Deal/AssignLenders/style'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { AssignLendersProps, ILenderModalState } from 'containers/Deal/AssignLenders/AssignLenders'
import { convertToPriceFormat } from 'utils/formatters'

const AssignLendersFunderContainer: FC<AssignLendersProps> = ({ dealActions }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useAssignLendersStyle()
  const { goBack, push } = useHistory()
  const { id, view } = useParams<{ id: string; type: 'inhouse' | 'outhouse'; view: string }>()
  const initValue: ILenderModalState = {
    lenderId: 0,
    dealId: id,
    syndicatorId: '',
    lendingPercentage: '',
    lendingAmount: '',
    isLead: false,
  }
  const queryCache = useQueryCache()

  const { data: dataDealInfo } = useQuery(['inhouse-deal', id], () => getDeal(id))

  const { data, isLoading } = useQuery(['getSyndicatorFunderList', id], () => getFunderLenders(id), {
    retry: false,
    cacheTime: 0,
  })

  const [modalAssignState, setModalAssignState] = useState({
    type: 'new',
    open: false,
    initValue: initValue,
    limit: 0,
    isFunderSyndicator: false,
    isHouseSyndicator: false,
  })

  const activeLenderActions = useMemo(() => {
    return dataDealInfo && dataDealInfo.data.status !== DealsStatus.declined && dataDealInfo.data.status !== DealsStatus.approved
  }, [dataDealInfo])

  const { columnsFunder } = useAssignLenders({
    disableActions: !activeLenderActions,
    handleEditFunder: (dataLender) => {
      setModalAssignState({
        type: dataLender.isAttachedToDeal ? 'edit' : 'new',
        open: true,
        limit: dataLender.limit,
        isFunderSyndicator: dataLender.isFunderSyndicator,
        isHouseSyndicator: dataLender.isHouseSyndicator,
        initValue: {
          lenderId: dataLender.id,
          dealId: id,
          syndicatorId: dataLender.syndicatorId,
          lendingPercentage: dataLender.lendingPercentage.toString(),
          isLead: dataLender.isLead,
          lendingAmount: dataLender.lendingAmount,
        },
      })
    },
  })

  const [addLender, { isLoading: isLoadingAddLender }] = useMutation(createLender, {
    onSuccess: (response) => {
      queryCache.fetchQuery(['getSyndicatorFunderList', id], () => getFunderLenders(id))
      enqueueSnackbar('Lender successfully assigned')
      setModalAssignState({
        type: 'new',
        open: false,
        initValue: initValue,
        limit: 0,
        isFunderSyndicator: false,
        isHouseSyndicator: false,
      })
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_LENDING_PERCENTAGE') enqueueSnackbar('Invalid percentage value')
      else if (err.response && err.response.data.description === 'LENDER_EXISTS') enqueueSnackbar('Lender already exist')
      else if (err.response && err.response.data.description === 'INVALID_FUND_AMOUNT')
        enqueueSnackbar('Funds are not available for this syndicator for this amount. Please adjust amount or select a different syndicator')
      else if (err.response && err.response.data.description === 'EXCEEDED_LENDING_PERCENTAGE') {
        enqueueSnackbar('You can not assign more than 100% for lenders in sum')
      } else if (err.response && err.response.data.description === 'LEAD_LENDER_PERCENTAGE_INVALID') {
        enqueueSnackbar('The lead lender should have at least 25 percent')
      } else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [editLenderMut, { isLoading: isLoadingEditLender }] = useMutation(editLender, {
    onSuccess: (response) => {
      queryCache.fetchQuery(['getSyndicatorFunderList', id], () => getFunderLenders(id))
      enqueueSnackbar('Lender successfully edited')
      setModalAssignState({
        type: 'new',
        open: false,
        initValue: initValue,
        limit: 0,
        isFunderSyndicator: false,
        isHouseSyndicator: false,
      })
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_LENDING_PERCENTAGE') {
        enqueueSnackbar('Invalid percentage value')
      } else if (err.response && err.response.data.description === 'LEAD_LENDER_PERCENTAGE_INVALID') {
        enqueueSnackbar('The lead lender should have at least 25 percent')
      } else if (err.response && err.response.data.description === 'EXCEEDED_LENDING_PERCENTAGE') {
        enqueueSnackbar('You can not assign more than 100% for lenders in sum')
      } else if (err.response && err.response.data.description === 'INVALID_FUND_AMOUNT')
        enqueueSnackbar('Funds are not available for this syndicator for this amount. Please adjust amount or select a different syndicator')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const mainLenderExist = useMemo(() => {
    if (data)
      return data.data.reduce((acc, val) => {
        if (val.isLead) return true
        return acc
      }, false)
    return true
  }, [data])

  const availableAmount = useMemo(() => {
    if (data && dataDealInfo && dataDealInfo.data.advanceAmount)
      return (
        dataDealInfo.data.advanceAmount -
        data.data.reduce((acc, val) => {
          return acc + val.lendingAmount
        }, 0)
      )
    return 0
  }, [data, dataDealInfo])

  const handleComplete = useCallback(() => {
    if (data) {
      if (!mainLenderExist || availableAmount > 0) {
        enqueueSnackbar(`The Amount should be equal 
        ${convertToPriceFormat(dataDealInfo?.data.advanceAmount)} and at least one main lead should be set`)
      } else
        push(
          generatePath(NEW_DEAL_URL, {
            id,
            view: 'edit',
            type: 'inhouse',
            step: 4,
          }),
        )
    }
  }, [mainLenderExist, availableAmount, enqueueSnackbar, push, data])

  const dealEditView = view === 'edit'

  if (dataDealInfo && dataDealInfo.data.type === CompanyType.OutHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  return (
    <Box mt={dealEditView ? '1.625rem' : 'initial'}>
      <Paper elevation={0}>
        <Box p="3rem" display="flex" alignItems="center">
          {!dealEditView && (
            <Box mr="1.5rem">
              <IconButton color="secondary" onClick={goBack} className={cn(classes.navButton)}>
                <ArrowLeft />
              </IconButton>
            </Box>
          )}
          <Typography variant="h2" color="textSecondary">
            Assign Lenders to Deal #{id}
          </Typography>
          <Box display="flex" alignItems="center" flex="1" justifyContent="flex-end">
            <Box m="auto">
              <Typography variant="h3" color="textSecondary">
                {availableAmount !== 0 ? <>{convertToPriceFormat(availableAmount)} available for syndication</> : 'Deal is fully syndicated'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <TableFunderLenders columns={columnsFunder} data={data ? data.data : []} loading={isLoading} />
        </Box>
        <ModalComponent open={modalAssignState.open}>
          <Box maxWidth={300} pt={2}>
            <AssignLenderForm
              advanceAmount={dataDealInfo?.data.advanceAmount || 0}
              mode={modalAssignState.type}
              initValue={modalAssignState.initValue}
              submit={(values) => {
                if (values.lendingAmount > modalAssignState.limit && !modalAssignState.isFunderSyndicator && !modalAssignState.isHouseSyndicator) {
                  enqueueSnackbar('The amount exceeds the lender limit')
                } else {
                  modalAssignState.type === 'new' ? addLender(values) : editLenderMut(values)
                }
              }}
              loading={isLoadingAddLender || isLoadingEditLender}
              dealId={id}
              cancel={() =>
                setModalAssignState((prevState) => ({
                  ...prevState,
                  open: false,
                }))
              }
            />
          </Box>
        </ModalComponent>
      </Paper>
      {dealActions && (
        <Box marginTop="2rem">
          <Button
            variant="contained"
            onClick={() => {
              push(
                generatePath(NEW_DEAL_URL, {
                  id,
                  view: 'edit',
                  type: 'inhouse',
                  step: 2,
                }),
              )
            }}
            startIcon={<ArrowLeftIcon className={classes.icon} />}
            className={classes.button}
          >
            Back
          </Button>
          <Button onClick={(e: any) => handleComplete()} color="primary" variant="contained">
            Complete
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AssignLendersFunderContainer
