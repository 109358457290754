import { ExportType } from 'typescript/enums/export'
import { IIsoDocuments } from 'typescript/interfaces/iso'
import { IPagination } from 'typescript/interfaces/pagination'
import { IPortfolioSummarySortFields } from 'typescript/enums/syndicators'
import { IUserPage } from 'typescript/interfaces/users'
import { DealPaidStatus } from 'typescript/interfaces/deals'
import { CompanyType, IState } from './companies'

export enum SyndicatorSortFields {
  Id = 'Id',
  Name = 'Name',
  DataJoined = 'DataJoined',
  Deals = 'Deals',
  AmountSyndicated = 'AmountSyndicated',
  StartingBalance = 'StartingBalance',
  AvailableBalance = 'AvailableBalance',
  LastTransactions = 'LastTransactions',
}

export interface SyndicatorForm {
  id?: number
  firstName: string
  lastName: string
  phone: string
  fax: string
  mobile: string
  email: string
  nameOnCheck: string
  userPage: IUserPage
  label: string
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  stateId: number | string
  managementFee: string | number
}

export interface SyndicatorListItem {
  id: number
  firstName: string
  lastName: string
  phone: string
  fax: string
  mobile: string
  startingBalance: string
  email: string
  deals: number
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: IState
  dataJoined: string
  availableBalance: number
  amountSyndicated: number
  lastTransaction: string
  managementFee: string | number
}

export interface ILightSyndicator {
  date: string
  id: number
  name: string
}

export interface ISyndicatorDetailEntity {
  id: number
  firstName: string
  lastName: string
  phone: string
  fax: string
  mobile: string
  email: string
  deals: number
  dataJoined: string
  availableBalance: number
  amountSyndicated: number
  lastTransaction: string
  nameOnCheck: string
  documents: IIsoDocuments[]
  userPage: IUserPage
  label: string
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: IState
  managementFee: string | number
}

export interface ISyndicatorExportParams {
  ExportType: ExportType
}

export interface ISyndicatorExportSummaryParams {
  ExportType: ExportType
}

export interface ISyndicatorReportExportParams {
  SyndicatorId: number
  ExportType: ExportType
}

export interface ISyndicatorReportResponse {
  name: string
  syndicatorReportList: ISyndicatorReportListItem[]
  totalDealAdvance: number
  totalSyndicated: number
  totalProjPayback: number
  totalToDatePayback: number
  totalCollected: number
  totalRemaining: number
  totalPnl: number
}

export interface ISyndicatorReportListItem {
  merchant: string
  deal: number
  dealEndDate: string
  participation: number
  dealAdvance: number
  syndicated: number
  projPayback: number
  toDatePayback: number
  collected: number
  remainingBalance: number
  startDate: string
  status: string
  totalCommission: number
  legalFee: number
  mgmFee: number
  defaultFee: number
  bankFee: number
  pnl: number
  tableOnlyRowExpanded?: boolean
}

export interface ISyndicatorLight {
  id: number
  name: string
}

export interface IConnectSyndicatorFileLinksToIsoParams {
  documentUrl: string
  syndicatorId: number | string
  description: string
}

export enum SyndicatorDealsSortingFields {
  merchant = 'BusinessName',
  deal = 'Id',
  dealEndDate = 'dealEndDate',
  participation = 'participation',
  dealAdvance = 'Advanced',
  syndicated = 'syndicated',
  projPayback = 'projPayback',
  toDatePayback = 'Payback',
}

type PaymentFrequencyType = 'Daily' | 'Biweekly' | 'Weekly' | 'Monthly'
type StatusPortfolioSummary = 'Draft' | 'Pending' | 'Declined' | 'Approved'

export interface IPortfolioSummaryItem {
  merchant: string
  company: string
  funded: string
  deal: number
  status: StatusPortfolioSummary
  paidStatus: DealPaidStatus
  createdDate: string
  paymentFrequency: PaymentFrequencyType
  term: number
  shares: number
  dealEndDate: string
  factorRate: number
  frequentPayment: number
  advanceAmount: number
  paybackAmount: number
  syndicationFee: number
  paidOff: number
  payOffAmount: number
  projectedRtr: number
}

export interface IPortfolioSummaryTotal {
  frequentPayment: number
  advanceAmount: number
  paybackAmount: number
  syndicationFee: number
  payOffAmount: number
  projectedRtr: number
  paidOff: number
}

export interface IPortfolioSummaryParams extends IPagination<IPortfolioSummarySortFields> {}

export interface ISyndicatedDealsParams extends IPagination<IPortfolioSummarySortFields> {
  SyndicatorId?: string
  ClientId?: string
}

export interface ISyndicatedDealStatsParams {
  SyndicatorId?: string
  DealId?: string
}

export interface ISyndicatedDealListItem {
  dealId: number
  businessName: string
  participation: number
  sharedInDeal: number
  purchased: number
  collected: number
  remainingBalance: number
  startDate: string
  status: string
}

export interface ISyndicatedDealStatsItem {
  month: number
  amount: number
}

export interface IGetSyndicatorParams extends IPagination<SyndicatorSortFields> {
  CompanyType?: CompanyType
}
