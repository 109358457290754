import React, { FC, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { useSyndicatorReport, TableSyndicatorReport } from 'hooks/useSyndicatorReport'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as DocumentOutlined } from 'assets/svg/DocumentOutlined.svg'
import SyndicatorReportTableFooter from 'components/SyndicatorReportTableFooter'
import { usePaginationList } from 'hooks/usePaginationList'
import { ISyndicatorReportListItem, SyndicatorDealsSortingFields } from 'typescript/interfaces/syndicators'
import { getSyndicator, getSyndicatorReport } from 'services/syndicators'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { convertEmptyField } from 'utils/formatters'
import { exportSyndicatorReport } from 'services/export'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { downloadFile } from 'utils/downloadFile'
import Button from 'UI/Button/ButtonWithPreloader'
import { ExportType } from 'typescript/enums/export'
import SyndicatedDealStats from 'components/SyndicatedDealStats'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import SearchField from 'UI/SearchField'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  button: {
    fontSize: '1.25rem',
  },
}))

const SyndicatorReportContainer: FC = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { id } = useParams<{ id: string }>()
  const { data: syndicatorData } = useQuery(['syndicatorById', id], () => getSyndicator(id))

  const { enqueueSnackbar } = useSnackbar()

  const [exportSyndicatorReportExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportSyndicatorReport, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { setPagination, handleFetchMore, handleSort, pagination, handleSearch } = usePaginationList<ISyndicatorReportListItem>({
    initState: {
      data: [],
      search: '',
      order: SyndicatorDealsSortingFields.deal,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getSyndicatorReportMut, { isLoading: isDealsLoading, data }] = useMutation(getSyndicatorReport, {
    onSuccess: (syndicatorReportData) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...syndicatorReportData.data.syndicatorReportList.map((row) => ({ ...row, tableOnlyRowExpanded: false }))],
        hasMore: syndicatorReportData.data.syndicatorReportList.length === 20,
      }))
    },
  })

  useEffect(() => {
    getSyndicatorReportMut({
      SyndicatorId: id,
      PageSize: 20,
      Query: pagination.search,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.search, pagination.page, pagination.order, pagination.sortOrder, id])

  const handleExpand = (dealId: number) => {
    setPagination((prevState) => {
      const newData = [...prevState.data]
      const rowToBeChanged = newData.find((row) => row.deal === dealId)
      rowToBeChanged!.tableOnlyRowExpanded = !rowToBeChanged!.tableOnlyRowExpanded

      return {
        ...prevState,
        data: newData,
      }
    })
  }

  const { columns } = useSyndicatorReport({ handleExpand })

  return (
    <Box className={additionalStyle.wrapper} component={Paper} mb="2rem">
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography className={s.title} variant="h2" color="textSecondary">
            {convertEmptyField(syndicatorData?.data.businessName)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box minWidth={200} mr="1rem">
            <SearchField handleSearch={handleSearch} name="search" label="Search" fullWidth />
          </Box>
          <Button
            loading={isLoadingExportExcel}
            onClick={() => exportSyndicatorReportExcelMut({ SyndicatorId: +id, ExportType: ExportType.excel })}
            variant="text"
            className={s.button}
            color="secondary"
            startIcon={<ExcelIcon />}
          >
            Export to Excel
          </Button>
          <Button
            loading={isLoadingExportExcel}
            variant="text"
            onClick={() => exportSyndicatorReportExcelMut({ SyndicatorId: +id, ExportType: ExportType.pdf })}
            className={s.button}
            color="secondary"
            startIcon={<DocumentOutlined />}
          >
            Export to PDF
          </Button>
        </Box>
      </Box>
      <TableSyndicatorReport
        columns={columns}
        handleSortChange={handleSort}
        loading={isDealsLoading}
        handleGetMore={handleFetchMore}
        data={pagination.data}
        hasMore={pagination.hasMore}
        expandable
        ExpandedComponent={SyndicatedDealStats}
        Footer={() => <SyndicatorReportTableFooter stats={data?.data} />}
      />
    </Box>
  )
}

export default SyndicatorReportContainer
