import React, { FC, useCallback, useContext } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import ClientInfoForm from 'components/ClientForms/ClientInfoForm'
import { useNewClientStyle } from 'containers/NewClient/style'
import { useMutation } from 'react-query'
import { createClient } from 'services/clients'
import { NEW_OWNER_URL } from 'constants/routes'
import { IClientInitValue } from 'typescript/interfaces/clients'
import { useSnackbar } from 'notistack'
import { useHistory, generatePath } from 'react-router-dom'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG, USER_EXISTS } from 'constants/errors'
import { UserContext } from 'contexts/userContext'
import { SET_CLIENTS_BY_USER } from 'constants/actionTypes'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const ClientInfoContainer: FC = () => {
  const { dispatch, state } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()
  const { goBack, push } = useHistory()
  const classes = useNewClientStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const [createClientMut, { isLoading }] = useMutation(createClient, {
    onSuccess: (data) => {
      push(
        generatePath(NEW_OWNER_URL, {
          id: data.data.id.toString(),
          type: 'create',
        }),
      )
      dispatch({
        type: SET_CLIENTS_BY_USER,
        payload: data.data,
      })
      enqueueSnackbar('Client successfully created')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'CLIENT_WITH_EMAIL_EXISTS') {
        enqueueSnackbar(USER_EXISTS)
      } else if (err.response?.data.description === 'EMAIL_USED_BY_ANOTHER_USER') {
        enqueueSnackbar(USER_EXISTS)
      } else if (err.response?.data.description === 'BANK_ACCOUNT_USED') {
        enqueueSnackbar('Current Bank Account already exists')
      } else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmitClientInfo = useCallback(
    (values: IClientInitValue) => {
      const copyValues = {
        ...values,
        grossAnnualIncome: values.grossAnnualIncome || null,
      }
      createClientMut(copyValues)
    },
    [createClientMut],
  )

  return (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        {!state.roles.isClient && (
          <Box ml="3rem" mr="1rem">
            <IconButton color="inherit" onClick={goBack}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        <Typography variant="h1" color="textSecondary">
          New Client
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <ClientInfoForm
            loading={isLoading}
            back={{
              handler: goBack,
              label: 'Back',
            }}
            submit={{
              handler: handleSubmitClientInfo,
              label: 'Save And Continue',
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default ClientInfoContainer
