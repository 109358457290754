import React, { useContext, useEffect } from 'react'
import { Box, Button, CircularProgress, Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import SwitchExtended from 'UI/Switch/SwitchExtended'
import SelectComponent from 'UI/Select'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useFormik } from 'formik'
import ClientAutoComplete from 'components/ClientAutoComplete'
import ISOAutoComplete from 'components/ISOAutoComplete'
import RepAutoComplete from 'components/RepAutoComplete'
import { paymentFrequency } from 'constants/paymentFrequency'
import { ICreateUpdateOuthouseDeal } from 'typescript/interfaces/deals'
import { CompanyLight, CompanyType } from 'typescript/interfaces/companies'
import { useMutation, useQuery } from 'react-query'
import { createOuthouseDeal, editOuthouseDeal, getDeal } from 'services/deals'
import { DEAL_INFO_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import { getPercentage } from 'utils/getPercentage'
import RequiredOption from 'UI/Select/RequiredOption'
import { checkForNaN, converToTwoSignsAfterDot } from 'utils/formatters'
import { IRepListItemLight } from 'typescript/interfaces/rep'
import CompanyAutoComplete from 'components/CompanyAutoComplete'
import { UserContext } from 'contexts/userContext'
import { DealsStatus } from 'typescript/enums/deals'
import { schemaOutHouseDeal } from './schema'

interface Props {}

const useStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
    marginRight: '1rem',
    padding: '3rem 3rem',
    '&:last-child': {
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    marginBottom: '2rem',
  },
  formContainer: {
    marginTop: '1.625rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '2.75rem',
  },
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  button: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  checkboxLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
    marginBottom: -3,
  },
  checkboxGrid: { paddingTop: '0 !important', paddingBottom: '0 !important' },
  comission: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    marginRight: '2rem',
    fontSize: '1.0625rem',
  },
}))

const DealInformationForm = (props: Props) => {
  const s = useStyles()
  const snack = useSnackbar()
  const { push, goBack, location } = useHistory()
  const { id } = useParams<{ id: string }>()
  const params = new URLSearchParams(location.search)
  const clientId = params.get('clientId')
  const { state } = useContext(UserContext)

  const [mutateCreateDeal, { isLoading: isCreateLoading }] = useMutation(createOuthouseDeal, {
    onSuccess(res) {
      push(DEAL_INFO_URL.replace(':id(\\d+)', `${res.data.id}`))
      snack.enqueueSnackbar(<Typography>Successfully created deal</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })
  const [mutateUpdateDeal, { isLoading: isUpdateLoading }] = useMutation(editOuthouseDeal, {
    onSuccess(res) {
      push(DEAL_INFO_URL.replace(':id(\\d+)', id))
      snack.enqueueSnackbar(<Typography>Successfully updated deal</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  const { isLoading, data } = useQuery(['outhouse-deal', id], () => getDeal(id), {
    onSuccess(res) {
      const {
        data: { id: dealId, client, outhouseLenders, paymentInfo, advanceAmount, term, factor, frequentPayment, iso, representatives, comments },
      } = res
      setValues({
        id: dealId,
        clientId: client?.id,
        outhouseLenders: outhouseLenders?.map((l) => l.id),
        advanceAmount: advanceAmount,
        term: term || undefined,
        factor: factor || undefined,
        frequentPayment,
        isoId: iso?.id,
        representativeIds: representatives && representatives.length > 0 ? representatives?.map((item) => item.id) : undefined,
        paymentFrequency: paymentInfo.paymentFrequency,
        paybackAmount: paymentInfo.payback,
        commissionToRep: representatives && representatives.length > 0 ? paymentInfo.commissionToRep : undefined,
        isoFee: paymentInfo.isoFee,
        psfFee: paymentInfo.psfFee,
        comments: comments ?? '',
        commissionSplit: paymentInfo.commissionSplit,
        earlyPayOffAmount: paymentInfo.earlyPayOffAmount,
      })
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    cacheTime: 0,
    enabled: id,
  })

  const isDisabledField = !(state.roles.isFunder || state.roles.isAdmin) || data?.data.status === DealsStatus.outhouseApproved

  const { values, errors, touched, handleChange, handleSubmit, setValues, setFieldValue } = useFormik<ICreateUpdateOuthouseDeal>({
    validationSchema: schemaOutHouseDeal,
    initialValues: {
      clientId: parseInt(clientId || '') || undefined,
      outhouseLenders: undefined,
      isoId: undefined,
      paymentFrequency: undefined,
      advanceAmount: undefined,
      paybackAmount: undefined,
      frequentPayment: undefined,
      commissionToRep: undefined,
      isoFee: undefined,
      commissionSplit: undefined,
      psfFee: undefined,
      psfOuthouseProcess: false,
      comments: '',
      term: undefined,
      factor: undefined,
      representativeIds: undefined,
      earlyPayOffAmount: undefined,
    },
    onSubmit: (formValues) => {
      if (id) {
        const copiedValues = {
          ...formValues,
          term: formValues.term || 0,
          factor: formValues.factor || 0,
          commissionToRep: formValues.commissionToRep || 0,
          earlyPayOffAmount: formValues.earlyPayOffAmount || 0,
        }
        mutateUpdateDeal(copiedValues)
      } else {
        mutateCreateDeal(formValues)
      }
    },
  })

  useEffect(() => {
    if (values.advanceAmount !== undefined && values.term !== undefined) {
      setFieldValue(
        'frequentPayment',
        converToTwoSignsAfterDot(
          (Number(values.advanceAmount || 0) * Number(values.factor || 0) - Number(values.earlyPayOffAmount || 0)) / Number(values.term || 1),
        ),
      )
    }
  }, [values.advanceAmount, values.factor, values.term, values.earlyPayOffAmount])

  useEffect(() => {
    if (values.advanceAmount !== undefined && values.factor !== undefined) {
      setFieldValue('paybackAmount', converToTwoSignsAfterDot(Number(values.advanceAmount || 0) * Number(values.factor || 0)))
    }
  }, [values.advanceAmount, values.factor, values.earlyPayOffAmount])

  if (isLoading) {
    return (
      <Box display="flex" height="400px" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  if (data && data.data.type === CompanyType.InHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  return (
    <Box display="flex" component="form" onSubmit={(e: any) => handleSubmit(e)} className={s.formContainer}>
      <Paper className={s.paper}>
        <Typography className={s.heading}>Add New Deal</Typography>
        <SwitchExtended
          className={s.switch}
          variants={[
            { title: 'Inhouse Deal', value: 'inhouse' },
            { title: 'Outhouse Deal', value: 'outhouse' },
          ]}
          value="outhouse"
          onClick={() =>
            push({
              pathname: '/deal/new/inhouse/1',
              search: location.search,
            })
          }
        />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <ClientAutoComplete
              label={<RequiredOption label="Choose the client" />}
              error={Boolean(touched.clientId && errors.clientId)}
              helperText={touched.clientId && errors.clientId}
              clientId={values.clientId}
              onChange={(e, value) => {
                if (value) handleChange({ target: { name: 'clientId', value: value.id } })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CompanyAutoComplete
              multiple
              paramsRequest={{
                CompanyType: CompanyType.OutHouse,
              }}
              // disabled={isDisabledToEdit || lendersDisabledToEdit}
              label={<RequiredOption label="OutHouse Lenders" />}
              error={Boolean(touched.outhouseLenders && errors.outhouseLenders)}
              helperText={touched.outhouseLenders && errors.outhouseLenders}
              companyId={values.outhouseLenders}
              onChange={(e, value: Array<CompanyLight>) => {
                if (value) handleChange({ target: { name: 'outhouseLenders', value: value.map((v) => v.id) } })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectComponent
              name="paymentFrequency"
              onChange={handleChange}
              labelProps={{ className: s.selectLabel }}
              fullWidth
              error={Boolean(touched.paymentFrequency && errors.paymentFrequency)}
              helperText={touched.paymentFrequency && errors.paymentFrequency}
              value={values.paymentFrequency}
              label="Payment Frequency"
            >
              {paymentFrequency.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="advanceAmount"
              onChange={handleChange}
              value={values.advanceAmount}
              error={Boolean(touched.advanceAmount && errors.advanceAmount)}
              helperText={touched.advanceAmount && errors.advanceAmount}
              label={<RequiredOption label="Advance Amount" />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: values.frequentPayment !== undefined }}
              disabled
              name="frequentPayment"
              onChange={handleChange}
              value={values.frequentPayment}
              error={Boolean(touched.frequentPayment && errors.frequentPayment)}
              helperText={touched.frequentPayment && errors.frequentPayment}
              label="Daily Payment"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="term"
              onChange={handleChange}
              value={values.term}
              error={Boolean(touched.term && errors.term)}
              helperText={touched.term && errors.term}
              label="Term"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              name="paybackAmount"
              InputLabelProps={{ shrink: values.paybackAmount !== undefined }}
              onChange={handleChange}
              value={values.paybackAmount}
              error={Boolean(touched.paybackAmount && errors.paybackAmount)}
              helperText={
                (touched.paybackAmount && errors.paybackAmount) || Boolean(!values.earlyPayOffAmount)
                  ? touched.paybackAmount && errors.paybackAmount
                  : `Total Payback Amount: ${Number(values?.paybackAmount) - Number(values?.earlyPayOffAmount)}`
              }
              label="Payback Amount"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="factor"
              onChange={handleChange}
              value={values.factor}
              error={Boolean(touched.factor && errors.factor)}
              helperText={touched.factor && errors.factor}
              label="Factor rate"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled={isDisabledField}
              name="earlyPayOffAmount"
              onChange={handleChange}
              value={values.earlyPayOffAmount}
              error={Boolean(touched.earlyPayOffAmount && errors.earlyPayOffAmount)}
              helperText={touched.earlyPayOffAmount && errors.earlyPayOffAmount}
              label="Early PayOff Amount"
            />
          </Grid>
          <Grid item xs={12}>
            <ISOAutoComplete
              label="ISO"
              error={Boolean(touched.isoId && errors.isoId)}
              helperText={touched.isoId && errors.isoId}
              isoId={values.isoId}
              onChange={(e, value) => {
                if (value) handleChange({ target: { name: 'isoId', value: value.id } })
                else {
                  handleChange({ target: { name: 'isoId', value: undefined } })
                  handleChange({ target: { name: 'isoFee', value: '' } })
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {!state.roles.isISO && (
              <TextField
                variant="outlined"
                fullWidth
                disabled={!values.representativeIds}
                name="commissionToRep"
                onChange={handleChange}
                value={values.commissionToRep ?? ''}
                error={Boolean(touched.commissionToRep && errors.commissionToRep)}
                helperText={
                  touched.commissionToRep && errors.commissionToRep
                    ? touched.commissionToRep && errors.commissionToRep
                    : `${getPercentage(values?.advanceAmount, values?.commissionToRep)}% of advance amount`
                }
                label="Commission To Rep"
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="isoFee"
              onChange={handleChange}
              value={values.isoFee}
              error={Boolean(touched.isoFee && errors.isoFee)}
              helperText={
                touched.isoFee && errors.isoFee
                  ? touched.isoFee && errors.isoFee
                  : `${getPercentage(values.advanceAmount, values.isoFee)}% of advance amount`
              }
              label="ISO Fee"
              disabled={!values.isoId}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={s.paper}>
        <Box>
          <Grid container spacing={8}>
            <Grid item container justify="flex-start" xs={6}>
              <Typography className={s.comission}>Total Commission:</Typography>
              <Typography className={s.comission}>{checkForNaN(Number(values.isoFee || 0) + Number(values.commissionToRep || 0))}</Typography>
            </Grid>
            <Grid item xs={12}>
              {!state.roles.isISO && (
                <RepAutoComplete
                  multiple
                  label="REP"
                  error={Boolean(touched.representativeIds && errors.representativeIds)}
                  helperText={touched.representativeIds && errors.representativeIds}
                  repId={values.representativeIds}
                  onChange={(e, value) => {
                    if (value && value.length > 0)
                      handleChange({
                        target: {
                          name: 'representativeIds',
                          value: value.map((v: IRepListItemLight) => v.id),
                        },
                      })
                    else {
                      setValues({
                        ...values,
                        representativeIds: undefined,
                        commissionToRep: undefined,
                      })
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="psfFee"
                onChange={handleChange}
                value={values.psfFee}
                error={Boolean(touched.psfFee && errors.psfFee)}
                helperText={
                  touched.psfFee && errors.psfFee
                    ? touched.psfFee && errors.psfFee
                    : `${getPercentage(values.advanceAmount, values.psfFee)}% of advance amount`
                }
                fullWidth
                label="PSF Fee"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Comments"
                name="comments"
                multiline
                rows={5}
                onChange={handleChange}
                value={values.comments}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop="auto">
          <Button variant="contained" onClick={goBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.button}>
            Back
          </Button>
          <Button disabled={isCreateLoading || isUpdateLoading} type="submit" color="primary" variant="contained">
            Complete
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default DealInformationForm
