import React, { FC, useMemo, useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as StartIcon } from 'assets/svg/PlustSquareIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon, ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import { generatePath, RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import { useAssignLendersStyle } from 'containers/Deal/AssignLenders/style'
import cn from 'classnames'
import { TableLenders, useAssignLenders } from 'hooks/useAssignLenders'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { createLender, deleteLender, editLender, getDeal, getLenderList } from 'services/deals'
import { getSyndicator } from 'services/syndicators'
import ModalComponent from 'UI/Modal'
import AssignLenderForm from 'components/AssignLenderForm'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError, AxiosResponse } from 'axios'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { DEAL_INFO_URL, NEW_DEAL_URL } from 'constants/routes'
import { DealsStatus } from 'typescript/enums/deals'
import { CompanyType } from 'typescript/interfaces/companies'
import { ILenderParams, ILenderEntity } from 'typescript/interfaces/deals'
import { ASSIGN_LENDERS_APPROVED_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import { ISyndicatorDetailEntity } from 'typescript/interfaces/syndicators'

export interface AssignLendersProps extends RouteComponentProps {
  dealActions?: boolean
}

export interface ILenderModalState extends ILenderParams {
  lenderId: number
}

const AssignLendersContainer: FC<AssignLendersProps> = ({ dealActions }) => {
  const { id, view } = useParams<{ id: string; type: 'inhouse' | 'outhouse'; view: string }>()
  const { hasPermission } = usePermission()

  const [lenders, setLenders] = useState<ILenderEntity[] | undefined>([])
  const [addedLendersIndex, setAddedLendersIndex] = useState<number[]>([])

  const initValue: ILenderModalState = {
    lenderId: 0,
    dealId: id,
    syndicatorId: '',
    lendingPercentage: '',
    lendingAmount: '',
    isLead: false,
  }
  const { data: dataDealInfo } = useQuery(['inhouse-deal', id], () => getDeal(id))
  const { goBack, push } = useHistory()
  const classes = useAssignLendersStyle()
  const { enqueueSnackbar } = useSnackbar()
  const queryCache = useQueryCache()
  const [modalAssignState, setModalAssignState] = useState({
    type: 'new',
    open: false,
    initValue: initValue,
  })
  const [modalRemoveLenderState, setModalRemoveLenderState] = useState({
    open: false,
    id: 0,
    idCompany: 0,
  })

  const activeLenderActions = useMemo(() => {
    return (
      dataDealInfo &&
      ((dataDealInfo.data.status !== DealsStatus.declined && dataDealInfo.data.status !== DealsStatus.approved) ||
        hasPermission(ASSIGN_LENDERS_APPROVED_PERM))
    )
  }, [dataDealInfo])

  const { columns } = useAssignLenders({
    disableActions: !activeLenderActions,
    handleEdit: (data) => {
      setModalAssignState({
        type: 'edit',
        open: true,
        initValue: {
          lenderId: data.id,
          dealId: id,
          syndicatorId: data.syndicatorId,
          lendingPercentage: data.lendingPercentage.toString(),
          lendingAmount: data.lendingAmount,
          isLead: data.isLead,
        },
      })
    },
    handleDelete: (idLender, idCompany) => {
      setModalRemoveLenderState({
        id: idLender,
        open: true,
        idCompany: idCompany,
      })
    },
  })
  const { data, isLoading } = useQuery(['getLendersList', id], () => getLenderList(id), {
    retry: false,
    cacheTime: 0,
  })

  useEffect(() => {
    setLenders(data?.data)
  }, [data])

  const addLenderModal = async (lenderData: ILenderParams) => {
    if (!lenders) return

    if (lenders.find((el) => el.syndicatorId === lenderData?.syndicatorId)) {
      return enqueueSnackbar('Lender already exist')
    }

    const indexOfLender = lenders.length

    const syndicatorData = await getSyndicator(lenderData.syndicatorId.toString())

    const preparedData: ISyndicatorDetailEntity = syndicatorData?.data

    const newLenderData: any = {
      ...lenderData,
      availableBalance: Number(preparedData.userPage.availableBalance),
      businessName: preparedData.businessName,
      id: Number(lenderData.lenderId),
      lendingPercentage: Number(lenderData.lendingPercentage),
      lendingAmount: Number(lenderData.lendingAmount),
    }

    const newLendersArray = [...lenders, newLenderData]

    setLenders(newLendersArray)

    if (!addedLendersIndex.includes(indexOfLender)) {
      setAddedLendersIndex([...addedLendersIndex, indexOfLender])
    }

    setModalAssignState({
      type: 'new',
      open: false,
      initValue: initValue,
    })
  }

  const editLenderModal = (lenderData: ILenderParams) => {
    if (data?.data.find((el) => el.syndicatorId === lenderData?.syndicatorId)) {
      editLenderMut(lenderData)
    } else {
      const lenderElIndex = lenders?.findIndex((el) => el.syndicatorId === lenderData?.syndicatorId)

      if (!lenderElIndex) return

      const preparedData = {
        ...lenderData,
        lenderId: Number(lenderData.lenderId),
        dealId: Number(lenderData.dealId),
        syndicatorId: Number(lenderData.syndicatorId),
        lendingAmount: Number(lenderData.lendingAmount),
        lendingPercentage: Number(lenderData.lendingPercentage),
      }

      if (!lenders) return

      const lendersArray = [...lenders]
      lendersArray?.splice(lenderElIndex, 1, { ...lenders?.[lenderElIndex], ...preparedData })

      setLenders(lendersArray)

      setModalAssignState({
        type: 'new',
        open: false,
        initValue: initValue,
      })
    }
  }

  const deleteLenderModal = (lenderData: { companyId: number; lenderId: number }) => {
    if (data?.data.find((el) => el.syndicatorId === lenderData?.companyId)) {
      deleteLenderMut(lenderData)
    } else {
      const newLendersArray = lenders?.filter((el) => el.syndicatorId !== lenderData?.companyId)

      setLenders(newLendersArray)

      setModalRemoveLenderState({
        idCompany: 0,
        id: 0,
        open: false,
      })
    }
  }

  const [addLender, { isLoading: isLoadingAddLender }] = useMutation(createLender)

  const [editLenderMut, { isLoading: isLoadingEditLender }] = useMutation(editLender, {
    onSuccess: (response) => {
      queryCache.fetchQuery(['getLendersList', id], () => getLenderList(id))
      enqueueSnackbar('Lender successfully edited')
      setModalAssignState({
        type: 'new',
        open: false,
        initValue: initValue,
      })
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_LENDING_PERCENTAGE') {
        enqueueSnackbar('Invalid percentage value')
      } else if (err.response && err.response.data.description === 'LEAD_LENDER_PERCENTAGE_INVALID') {
        enqueueSnackbar('The lead lender should have at least 25 percent')
      } else if (err.response && err.response.data.description === 'EXCEEDED_LENDING_PERCENTAGE') {
        enqueueSnackbar('You can not assign more than 100% for lenders in sum')
      } else if (err.response && err.response.data.description === 'INVALID_FUND_AMOUNT')
        enqueueSnackbar('Funds are not available for this syndicator for this amount. Please adjust amount or select a different syndicator')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [deleteLenderMut, { isLoading: isLoadingDeleteLender }] = useMutation(deleteLender, {
    onSuccess: () => {
      queryCache.fetchQuery(['getLendersList', id], () => getLenderList(id))
      enqueueSnackbar('Lender successfully deleted')
      setModalRemoveLenderState({
        idCompany: 0,
        id: 0,
        open: false,
      })
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const mainLenderExist = useMemo(() => {
    if (lenders)
      return lenders.reduce((acc, val) => {
        if (val.isLead) return true
        return acc
      }, false)
    return true
  }, [lenders])

  const availablePercentage = useMemo(() => {
    if (lenders)
      return (
        100 -
        lenders.reduce((acc, val) => {
          return acc + Number(val.lendingPercentage)
        }, 0)
      )
    return 100
  }, [lenders])

  const handleComplete = async () => {
    if (lenders) {
      if (!mainLenderExist || availablePercentage > 0) {
        enqueueSnackbar('The Percentage should be equal 100% and at least one main lead should be set')
      } else {
        let error: string = ''

        const addedPromiseArray: Promise<AxiosResponse<ILenderEntity> | undefined>[] = addedLendersIndex.map(async (el, index) => {
          const lenderData = lenders?.[el]

          if (!lenderData) return

          return addLender(
            {
              dealId: id,
              lenderId: lenderData?.id,
              syndicatorId: lenderData?.syndicatorId,
              lendingPercentage: lenderData?.lendingPercentage,
              lendingAmount: lenderData?.lendingAmount,
              isLead: lenderData?.isLead,
            },
            {
              onSuccess: () => {
                if (index === addedLendersIndex.length - 1) {
                  error = ''
                }
              },
              onError: (err: any) => {
                if (index === addedLendersIndex.length - 1) {
                  error = err?.response?.data?.description

                  if (error === 'INVALID_LENDING_PERCENTAGE') enqueueSnackbar('Invalid percentage value')
                  else if (error === 'LENDER_EXISTS') enqueueSnackbar('Lender already exist')
                  else if (error === 'INVALID_FUND_AMOUNT')
                    enqueueSnackbar(
                      `Funds are not available for this syndicator with #${lenderData?.id} for this amount. 
                    Please adjust amount or select a different syndicator`,
                    )
                  else if (error === 'EXCEEDED_LENDING_PERCENTAGE') {
                    enqueueSnackbar('You can not assign more than 100% for lenders in sum')
                  } else if (error === 'LEAD_LENDER_PERCENTAGE_INVALID') {
                    enqueueSnackbar('The lead lender should have at least 25 percent')
                  } else enqueueSnackbar(SOMETHING_WENT_WRONG)
                }
              },
            },
          )
        })

        await Promise.all(addedPromiseArray)

        if (error) return

        push(
          generatePath(NEW_DEAL_URL, {
            id,
            view: 'edit',
            type: 'inhouse',
            step: 4,
          }),
        )
      }
    }
  }

  const dealEditView = view === 'edit'

  if (dataDealInfo && dataDealInfo.data.type === CompanyType.OutHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  return (
    <Box mt={dealEditView ? '1.625rem' : 'initial'}>
      <Paper elevation={0}>
        <Box p="3rem" display="flex" alignItems="center">
          {!dealEditView && (
            <Box mr="1.5rem">
              <IconButton color="secondary" onClick={goBack} className={cn(classes.navButton)}>
                <ArrowLeft />
              </IconButton>
            </Box>
          )}
          <Typography variant="h2" color="textSecondary">
            Assign Lenders to Deal #{id}
          </Typography>
          <Box display="flex" alignItems="center" flex="1" justifyContent="flex-end">
            <Box m="auto">
              <Typography variant="h3" color="textSecondary">
                {availablePercentage ? `${availablePercentage.toFixed(2)}% available for syndication` : 'Deal is fully syndicated'}{' '}
              </Typography>
            </Box>
            <Box>
              <Button
                disabled={!activeLenderActions}
                variant="text"
                color="secondary"
                startIcon={<StartIcon />}
                onClick={() =>
                  setModalAssignState({
                    type: 'new',
                    initValue: initValue,
                    open: true,
                  })
                }
              >
                Assign Lender
              </Button>
            </Box>
            {/*<Box ml={5}>*/}
            {/*  <Button variant="text" color="secondary" startIcon={<ExcelIcon />}>*/}
            {/*    Export to Excel*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </Box>
        </Box>
        <Box>
          <TableLenders columns={columns} data={lenders || []} loading={isLoading} />
        </Box>
        <ModalComponent open={modalAssignState.open}>
          <Box maxWidth={300} pt={2}>
            <AssignLenderForm
              advanceAmount={(dataDealInfo?.data.flexAmount ?? dataDealInfo?.data.advanceAmount) || 0}
              mode={modalAssignState.type}
              initValue={modalAssignState.initValue}
              submit={modalAssignState.type === 'new' ? addLenderModal : editLenderModal}
              loading={isLoadingAddLender || isLoadingEditLender}
              dealId={id}
              cancel={() =>
                setModalAssignState((prevState) => ({
                  ...prevState,
                  open: false,
                }))
              }
            />
          </Box>
        </ModalComponent>
        <ModalComponent open={modalRemoveLenderState.open}>
          <ConfirmationContent
            isLoading={isLoadingDeleteLender}
            handleCancel={() =>
              setModalRemoveLenderState((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            handleConfirm={() =>
              deleteLenderModal({
                companyId: modalRemoveLenderState.idCompany,
                lenderId: modalRemoveLenderState.id,
              })
            }
            text="Are you sure you want to delete lender?"
          />
        </ModalComponent>
      </Paper>
      {dealActions && (
        <Box ml="3rem" marginTop="2rem">
          <Button
            variant="contained"
            onClick={() => {
              push(
                generatePath(NEW_DEAL_URL, {
                  id,
                  view: 'edit',
                  type: 'inhouse',
                  step: 2,
                }),
              )
            }}
            startIcon={<ArrowLeftIcon className={classes.icon} />}
            className={classes.button}
          >
            Back
          </Button>
          <Button onClick={(e: any) => handleComplete()} color="primary" variant="contained">
            Complete
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AssignLendersContainer
