import React from 'react'
import { useFormik } from 'formik'
import Button from 'UI/Button/ButtonWithPreloader'
import { TextField } from '@material-ui/core'
import RequiredOption from 'UI/Select/RequiredOption'

export interface Guarantor {
  id: number
  name: string
  address: string
}

interface Props {
  initialValues: Guarantor
  guarantors: Guarantor[]
  name: string
  propsFormValues: any
  disabled: boolean | undefined
  setGuarantors: React.Dispatch<React.SetStateAction<Guarantor[]>>
  handleClosePopover: () => void
  classes: any
}

export const AddGuarantorForm = ({
  initialValues,
  propsFormValues,
  guarantors,
  setGuarantors,
  handleClosePopover,
  name,
  disabled,
  ...props
}: Props) => {
  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    enableReinitialize: false,
    onSubmit: (formValues) => {
      guarantors.push({ name: formValues.name, address: formValues.address, id: Number(guarantors.length + 1) })
      if (guarantors) {
        setGuarantors(guarantors)
        handleClosePopover()
      }
    },
  })

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <form className={props.classes.container} onSubmit={handleSubmit}>
      <TextField
        {...props}
        variant="outlined"
        placeholder="Business name"
        InputLabelProps={{ shrink: true }}
        name="name"
        value={values.name}
        onChange={handleChange}
        label={<RequiredOption label="Business Name" />}
      />
      <TextField
        {...props}
        variant="outlined"
        placeholder="Address"
        InputLabelProps={{ shrink: true }}
        name="address"
        value={values.address}
        onChange={handleChange}
        label={<RequiredOption label="Address" />}
      />
      <Button type="submit" color="primary" variant="contained">
        Add
      </Button>
    </form>
  )
}
