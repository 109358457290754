import {
  COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT,
  MAX_VALUE_IS,
  MIN_VALUE_IS,
  MIN_VALUE_IS_ONE,
  NUMBERS_ONLY,
  NUMBER_CANNOT_BE_NEGATIVE,
  REQUIRED_FIELD,
  MUST_BE_INTEGER,
  ONLY_DIGITS,
  FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT,
} from 'constants/errors'
import { paymentFrequency } from 'constants/paymentFrequency'
import * as yup from 'yup'
import { integerReg } from 'constants/regExp'
import { extendTypes } from 'typescript/interfaces/deals'

export const schemaInHouseDeal = yup.object().shape({
  clientId: yup.number().typeError(NUMBERS_ONLY).required(REQUIRED_FIELD),
  // lendersIds: yup
  //   .array()
  //   .test('no-undefined', REQUIRED_FIELD, (val) => val !== undefined)
  //   .typeError(REQUIRED_FIELD)
  //   .min(1, REQUIRED_FIELD),
  companyId: yup.string().required(REQUIRED_FIELD),
  isoId: yup.number().typeError(NUMBERS_ONLY),
  paymentFrequency: yup.string().oneOf(paymentFrequency).required(REQUIRED_FIELD),
  advanceAmount: yup.string().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).matches(integerReg, ONLY_DIGITS),
  commissionToRep: yup
    .string()
    .nullable()
    .when('representativeIds', {
      is: (val) => !!val,
      then: yup.string().required(REQUIRED_FIELD),
    })
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function (value) {
      if (!this.parent.representativeIds) return true

      const rFee = value || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= rFee
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  isoFee: yup
    .string()
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      if (!this.parent.isoId) return true

      const іFee = this.parent.isoFee || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= іFee
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  commissionSplit: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  psfFee: yup
    .string()
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      const pFee = this.parent.psfFee || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= pFee
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  syndicationFee: yup.string().matches(integerReg, ONLY_DIGITS).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  payOffAmount: yup.string().matches(integerReg, ONLY_DIGITS).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  earlyPayOffAmount: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  comments: yup.string(),
  term: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(integerReg, MUST_BE_INTEGER)
    .test('integer', MIN_VALUE_IS_ONE, function (val) {
      return parseInt(val || '') >= 1
    }),
  factor: yup
    .number()
    .typeError(NUMBERS_ONLY)
    .min(1, MIN_VALUE_IS.replace(':value', '1'))
    .max(2, MAX_VALUE_IS.replace(':value', '2'))

    .required(REQUIRED_FIELD),
  representativeIds: yup.number().typeError(NUMBERS_ONLY),
  // monthlyCrmFee: yup
  //   .number()
  //   .required(REQUIRED_FIELD)
  //   .typeError(NUMBERS_ONLY)
  //   .test('float', INVALID_SYMBOLS, function () {
  //     return valiadateFloatWithTwoDigits(+this.parent.monthlyCrmFee)
  //   })
  //   .min(99, MIN_VALUE_IS.replace(':value', '99')),
  bankAccountId: yup.string().required(REQUIRED_FIELD),
  flexAmount: yup.mixed().when('isFlexDeal', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .typeError(NUMBERS_ONLY)
      .matches(integerReg, ONLY_DIGITS)
      .test('flexAmount', FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT, function (val) {
        if (!val) return false

        return Number(val) <= Number(this.parent.advanceAmount)
      }),
    otherwise: yup.mixed(),
  }),
  extendType: yup.mixed().when('isFlexDeal', {
    is: true,
    then: yup.string().oneOf(extendTypes).required(REQUIRED_FIELD),
    otherwise: yup.string().nullable(),
  }),
})

export const documentsValidationSchema = yup.object().shape({
  documents: yup.object().shape({
    VoidedCheck: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent?.VoidedCheck.length > 0 || grandparent?.uploadedDocuments.VoidedCheck.length > 0
    }),
    Application: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent.Application.length > 0 || grandparent?.uploadedDocuments?.Application.length > 0
    }),
    DriverLicense: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent.DriverLicense.length > 0 || grandparent?.uploadedDocuments?.DriverLicense.length > 0
    }),
    BankStatements: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent.BankStatements.length > 0 || grandparent?.uploadedDocuments?.BankStatements.length > 0
    }),
  }),
  uploadedDocuments: yup.object().shape({}),
})
