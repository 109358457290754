import React, { FC, useReducer } from 'react'
import { UserContext, reducer, initState } from 'contexts/userContext'
import { useQuery } from 'react-query'
import { getCurrentUser } from 'services/users'
import { getMeClient } from 'services/clients'
import { JWT_TOKEN, USER_ROLE } from 'constants/localStorageKeys'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { CHANGE_CURRENT_USER, SET_CLIENTS_BY_USER, SET_ISO, SET_REP, SET_SYNDICATOR, SET_VENDOR } from 'constants/actionTypes'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { CHOOSE_ROLE, SIGN_IN_URL } from 'constants/routes'
import { getVendorById } from 'services/vendor'
import { getRepById } from 'services/rep'
import { getIsoById } from 'services/iso'
import { getSyndicator } from 'services/syndicators'

const UserProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { Provider } = UserContext
  const { push } = useHistory()

  const { isLoading } = useQuery(['currentUser', localStorage.getItem(JWT_TOKEN)], getCurrentUser, {
    onSuccess: (dataRes) => {
      const lsRole = localStorage.getItem(USER_ROLE)
      const userRole = dataRes.data.userRoles.find((role) => role.name === lsRole)

      if (lsRole && userRole) {
        dispatch({
          type: CHANGE_CURRENT_USER,
          payload: { ...dataRes.data, userRoles: [userRole] },
        })
      } else if (!lsRole && dataRes.data.userRoles.length === 1) {
        localStorage.setItem(USER_ROLE, dataRes.data.userRoles[0].name)
        dispatch({
          type: CHANGE_CURRENT_USER,
          payload: { ...dataRes.data, userRoles: [dataRes.data.userRoles[0]] },
        })
      } else {
        localStorage.removeItem(USER_ROLE)
        push(CHOOSE_ROLE)
      }
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.status === 401 && !localStorage.getItem(JWT_TOKEN)) {
        push(SIGN_IN_URL)
      }
    },
    enabled: !state.user,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  })

  const { isLoading: isLoadingClients } = useQuery(['clientsByUser', localStorage.getItem(JWT_TOKEN)], () => getMeClient(), {
    onSuccess: (dataClients) => {
      dispatch({
        type: SET_CLIENTS_BY_USER,
        payload: dataClients.data,
      })
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'CLIENT_NOT_FOUND')
        dispatch({
          type: SET_CLIENTS_BY_USER,
          payload: null,
        })
    },
    retry: false,
    enabled: !state.client && state.roles.isClient,
  })

  useQuery(['vendorMe', localStorage.getItem(JWT_TOKEN)], () => getVendorById('me'), {
    onSuccess: (dataVendor) => {
      dispatch({
        type: SET_VENDOR,
        payload: dataVendor.data,
      })
    },
    retry: false,
    enabled: !state.vendor && state.roles.isCollector,
  })

  useQuery(['repMe', localStorage.getItem(JWT_TOKEN)], () => getRepById('me'), {
    onSuccess: (dataRep) => {
      dispatch({
        type: SET_REP,
        payload: dataRep.data,
      })
    },
    retry: false,
    enabled: !state.rep && (state.roles.isRep || state.roles.isFunder),
  })

  useQuery(['isoMe', localStorage.getItem(JWT_TOKEN)], () => getIsoById('me'), {
    onSuccess: (dataIso) => {
      dispatch({
        type: SET_ISO,
        payload: dataIso.data,
      })
    },
    retry: false,
    enabled: !state.iso && state.roles.isISO,
  })

  useQuery(['syndicatorMe', localStorage.getItem(JWT_TOKEN)], () => getSyndicator('me'), {
    onSuccess: (dataSyndicator) => {
      dispatch({
        type: SET_SYNDICATOR,
        payload: dataSyndicator.data,
      })
    },
    retry: false,
    enabled: !state.syndicator && (state.roles.isSyndicator || state.roles.isFunder),
  })

  return (
    <Provider
      value={{
        state,
        dispatch,
      }}
    >
      {(state.user || !isLoading) && (!isLoadingClients || state.client === null || state.client) ? (
        <>{children}</>
      ) : (
        <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      )}
    </Provider>
  )
}

export default UserProvider
