import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import FileDragNDrop from 'components/FileDragNDrop'
import React, { useCallback, useContext, useState } from 'react'
import { removeAtIndex } from 'utils/arrayHelpers'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { deleteFileFromDeal, getDeal, uploadDocuments } from 'services/deals'
import { DEAL_INFO_URL, NEW_DEAL_URL, VIEW_CLIENT_URL } from 'constants/routes'
import {
  IDocuments,
  IUploadDocWithDocType,
  IUploadedCategoriesKeys,
  IUploadedDocuments,
  IDealDocumentConnectParamsResponse,
} from 'typescript/interfaces/deals'
import { IDocumentToSend } from 'typescript/interfaces/clients'
import { useFormik } from 'formik'
import { CompanyType } from 'typescript/interfaces/companies'
import { UserContext } from 'contexts/userContext'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { AxiosError } from 'axios'
import { documentsValidationSchema } from './schema'

interface Props {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '3rem',
    marginTop: '1.625rem',
    marginBottom: '2rem',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: '1.5rem',
  },
  dndContainer: {
    marginBottom: '3.5rem',
  },
  button: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
}))

const DocumentsForm = (props: Props) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const uploadDocs = params.get('uploadDocs')
  const [deleteFile, setDeleteFile] = useState<{
    file: IDealDocumentConnectParamsResponse | undefined
    open: boolean
  }>({
    file: undefined,
    open: false,
  })
  const { state } = useContext(UserContext)
  const s = useStyles()
  const { push, goBack } = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const { data } = useQuery(['inhouse-deal', id], () => getDeal(id), {
    onSuccess(res) {
      const {
        data: { documents },
      } = res

      const documentsToSet: IUploadedDocuments = {
        VoidedCheck: [],
        Application: [],
        DriverLicense: [],
        BankStatements: [],
        CreditProcessingStatements: [],
        W9: [],
        Optional: [],
        Contract: [],
      }

      documents.forEach((doc) => {
        documentsToSet[doc.documentType].push(doc)
      })

      setFieldValue('uploadedDocuments', documentsToSet)
    },
    onError() {
      enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    enabled: id,
  })

  const [mutateRemove, { isLoading: isLoadingDeleteFile }] = useMutation(
    ({ id: docId, name }: { id: string; name: IUploadedCategoriesKeys }) => deleteFileFromDeal(docId),
    {
      onSuccess(_, variables) {
        enqueueSnackbar(<Typography>Successfully deleted document</Typography>)
        setFieldValue(
          `uploadedDocuments.${variables.name}`,
          values.uploadedDocuments[variables.name].filter((doc) => `${doc.id}` !== variables.id),
        )
        setDeleteFile({
          file: undefined,
          open: false,
        })
      },
      onError: (err: AxiosError) => {
        setDeleteFile({
          file: undefined,
          open: false,
        })
        if (err.response && err.response.data.description === 'CANT_DELETE_LAST_DOCUMENT')
          enqueueSnackbar('The last file of this type cannot be deleted')
      },
    },
  )

  const { values, setFieldValue, errors, touched, handleSubmit } = useFormik<{ documents: IDocuments; uploadedDocuments: IUploadedDocuments }>({
    initialValues: {
      documents: {
        VoidedCheck: [],
        Application: [],
        DriverLicense: [],
        BankStatements: [],
        CreditProcessingStatements: [],
        W9: [],
        Optional: [],
        Contract: [],
      },
      uploadedDocuments: {
        VoidedCheck: [],
        Application: [],
        DriverLicense: [],
        BankStatements: [],
        CreditProcessingStatements: [],
        W9: [],
        Optional: [],
        Contract: [],
      },
    },
    validationSchema: documentsValidationSchema,
    onSubmit: (formValues) => {
      const documentsToSend: IDocumentToSend[] = []

      // eslint-disable-next-line guard-for-in
      for (const category in formValues.documents) {
        formValues.documents[category as IUploadedCategoriesKeys].forEach(
          (f) => documentsToSend.push({ file: f, documentType: category as IUploadedCategoriesKeys }),
          // eslint-disable-next-line
        )
      }

      mutate(documentsToSend)
    },
  })

  const handleDrop = (files: File[], name: IUploadedCategoriesKeys) => {
    let filesToAdd: File[] = []
    // if (files.length > 0 && !files.some((f) => f.name.endsWith('.pdf'))) {
    //   enqueueSnackbar("Files with inappropriate format can't be added")
    //   filesToAdd = files.filter((f) => f.name.endsWith('.pdf'))
    // } else
    if (files.length > 0 && files.some((f) => f.size > 50000000)) {
      enqueueSnackbar('Files must be no more than 50MB.')
      filesToAdd = files.filter((f) => f.size <= 50000000)
    } else {
      filesToAdd = files
    }
    setFieldValue(`documents.${name}`, [...values.documents[name], ...filesToAdd])
    // setDocuments((old) => ({ ...old, [name]: [...old[name as Names], ...filesToAdd] }))
  }
  const handleRemove = (index: number, name: IUploadedCategoriesKeys) => {
    setFieldValue(`documents.${name}`, removeAtIndex(values.documents[name], index))
  }

  const [mutate, { isLoading }] = useMutation((docs: IUploadDocWithDocType[]) => uploadDocuments(docs, +id), {
    onSuccess() {
      if (type === 'client-deal')
        push(
          generatePath(VIEW_CLIENT_URL, {
            id: state.client ? state.client.id : '',
          }),
        )
      else if (uploadDocs)
        push(
          generatePath(DEAL_INFO_URL, {
            id,
          }),
        )
      else
        push(
          generatePath(NEW_DEAL_URL, {
            id,
            view: 'edit',
            type: 'inhouse',
            step: 3,
          }),
        )
    },
  })

  const isDisabledToDelete = data?.data.status === 'Declined' || data?.data.status === 'Approved'

  const handleRemoveUploaded = useCallback(
    (documentId: number, name: IUploadedCategoriesKeys) => {
      const deletedFile = values.uploadedDocuments[name].find((doc) => doc.id === documentId)
      // const filteredFiles = values.uploadedDocuments[name].filter((doc) => doc.id !== documentId)
      setDeleteFile({
        file: deletedFile,
        open: true,
      })
      // setFieldValue(`uploadedDocuments.${name}`, filteredFiles)
      // mutateRemove({ id: `${documentId}`, name })
    },
    [setFieldValue, mutateRemove, values],
  )

  if (data && data.data.type === CompanyType.OutHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  const handleGoBack = useCallback(() => {
    if (uploadDocs) goBack()
    else
      push(
        generatePath(NEW_DEAL_URL, {
          id: id,
          view: 'edit',
          type: type,
          step: 1,
        }),
      )
  }, [type, id, push, uploadDocs])

  return (
    <Paper className={s.paper}>
      {isLoading && 'LOADING'}
      <Typography className={s.sectionTitle}>Required Documents</Typography>
      <Grid className={s.dndContainer} container spacing={4}>
        <Grid xs={12} sm={6} lg={3} item>
          <FileDragNDrop
            title="Voided check"
            name="VoidedCheck"
            disabledDelete={isDisabledToDelete}
            onDrop={handleDrop}
            uploadedFiles={values.uploadedDocuments.VoidedCheck}
            uploaded={Boolean(values.uploadedDocuments.VoidedCheck.length)}
            files={values.documents.VoidedCheck}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.VoidedCheck && errors.documents?.VoidedCheck)}
          />
        </Grid>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="Application"
            name="Application"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.Application}
            uploaded={Boolean(values.uploadedDocuments.Application.length)}
            files={values.documents.Application}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.Application && errors.documents?.Application)}
          />
        </Grid>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="Driver's License"
            name="DriverLicense"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.DriverLicense}
            uploaded={Boolean(values.uploadedDocuments.DriverLicense.length)}
            files={values.documents.DriverLicense}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.DriverLicense && errors.documents?.DriverLicense)}
          />
        </Grid>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="Bank Statements"
            name="BankStatements"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.BankStatements}
            uploaded={Boolean(values.uploadedDocuments.BankStatements.length)}
            files={values.documents.BankStatements}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.BankStatements && errors.documents?.BankStatements)}
          />
        </Grid>
      </Grid>
      <Typography className={s.sectionTitle}>Optional Documents</Typography>
      <Grid className={s.dndContainer} container spacing={4}>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="Contract"
            name="Contract"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.Contract}
            uploaded={Boolean(values.uploadedDocuments.Contract.length)}
            files={values.documents.Contract}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.Contract && errors.documents?.Contract)}
          />
        </Grid>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="Optional"
            name="Optional"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.Optional}
            uploaded={Boolean(values.uploadedDocuments.Optional.length)}
            files={values.documents.Optional}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
          />
        </Grid>
        <Grid lg={3} xs={12} sm={6} item>
          <FileDragNDrop
            title="W9"
            name="W9"
            onDrop={handleDrop}
            disabledDelete={isDisabledToDelete}
            uploadedFiles={values.uploadedDocuments.W9}
            uploaded={Boolean(values.uploadedDocuments.W9.length)}
            files={values.documents.W9}
            onRemove={handleRemove}
            onRemoveUploaded={handleRemoveUploaded}
            error={Boolean(touched.documents?.W9 && errors.documents?.W9)}
          />
        </Grid>
      </Grid>
      <Box marginTop="auto">
        <Button variant="contained" onClick={handleGoBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.button}>
          Back
        </Button>
        <Button disabled={isLoading} onClick={(e: any) => handleSubmit(e)} color="primary" variant="contained">
          {uploadDocs ? 'Save' : 'Next'}
        </Button>
      </Box>
      <ModalComponent open={deleteFile.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteFile}
          text="Are you sure you want to delete file?"
          handleCancel={() =>
            setDeleteFile({
              file: undefined,
              open: false,
            })
          }
          handleConfirm={() => {
            mutateRemove({
              id: deleteFile.file?.id.toString() || '',
              name: deleteFile.file?.documentType || 'VoidedCheck',
            })
          }}
        />
      </ModalComponent>
    </Paper>
  )
}

export default DocumentsForm
