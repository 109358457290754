export const CLIENTS_MODULE_PERM = 'CLIENTS_PERM'
export const CLIENT_CREATE_UPDATE_VIEW = 'CLIENT_CREATE_UPDATE_VIEW'
export const CLIENT_INVITE_PERM = 'CLIENT_INVITE_PERM'
export const PERMISSION_CREATE_PERSONAL_CLIENT = 'PERMISSION_CREATE_PERSONAL_CLIENT'
export const NACHA_LIST_PERM = 'NACHA_LIST_PERM'
export const WITHDRAWAL_PERM = 'WITHDRAWAL_PERM'
export const TRANSACTION_PERM = 'TRANSACTION_PERM'
export const LEGAL_FEE_PERM = 'LEGAL_FEE_PERM'
export const TODAY_PAYMENT_REPORT_PERM = 'TODAY_PAYMENT_REPORT_PERM'
export const ACH_MODULE_PERM = 'ACH_MODULE_PERM'
export const INHOUSE_COMPANY_MODULE_PERM = 'INHOUSE_COMPANY_MODULE_PERM'
export const OUTHOUSE_COMPANY_MODULE_PERM = 'OUTHOUSE_COMPANY_MODULE_PERM'
export const SYNDICATOR_CREATE_PERM = 'SYNDICATOR_CREATE_PERM'
export const SYNDICATORS_MODULE_PERM = 'SYNDICATORS_PERM'
export const SYNDICATORS_VIEW_PERM = 'SYNDICATORS_VIEW_PERM'
export const DASHBOARD_MODULE_PERM = 'DASHBOARD_MODULE_PERM'
export const DEAL_MODULE_PERM = 'DEAL_MODULE_PERM'
export const ISO_MODULE_PERM = 'ISO_MODULE_PERM'
export const REP_MODULE_PERM = 'REP_MODULE_PERM'
export const NOTIFICATIONS_VIEW_PERM = 'NOTIFICATIONS_VIEW_PERM'
export const NOTIFICATIONS_CREATE_PERM = 'NOTIFICATIONS_CREATE_PERM'
export const NOTIFICATIONS_CREATE_WITH_ROLES_PERM = 'NOTIFICATIONS_CREATE_WITH_ROLES_PERM'
export const VENDORS_MODULE_PERM = 'VENDORS_MODULE_PERM'
export const VENDORS_ADD_PERM = 'VENDORS_ADD_PERM'
export const FUNDING_QUES_PERM = 'FUNDING_QUES_PERM'
export const WITHDRAWAL_QUES_PERM = 'WITHDRAWAL_QUES_PERM'
export const USER_ROLES_MODULE_PERM = 'USER_ROLES_MODULE_PERM'
export const USER_MODULE_PERM = 'USER_MODULE_PERM'
export const REQUEST_FUNDER_INFO_PERM = 'REQUEST_FUNDER_INFO_PERM'
export const PORTFOLIO_SUMMARY_PERM = 'PORTFOLIO_SUMMARY_PERM'
export const APPROVE_FUNDERS_LIMIT_PERM = 'APPROVE_FUNDERS_LIMIT_PERM'
export const LATEST_DEALS_PERM = 'LATEST_DEALS_PERM'
export const ASSIGN_LENDERS_PERM = 'ASSIGN_LENDERS_PERM'
export const ASSIGN_LENDERS_APPROVED_PERM = 'ASSIGN_LENDERS_APPROVED_PERM'
export const CREATE_INHOUSE_COMPANY_PERM = 'CREATE_INHOUSE_COMPANY_PERM'
export const CREATE_OUTHOUSE_COMPANY_PERM = 'CREATE_OUTHOUSE_COMPANY_PERM'
export const CREATE_CLIENT_DEAL = 'CREATE_CLIENT_DEAL'
export const REP_PERFORMANCE_PERM = 'REP_PERFORMANCE_PERM'
export const SYNDICATOR_OWN_VIEW_PERM = 'SYNDICATOR_OWN_VIEW_PERM'
export const VENDOR_OWN_VIEW_PERM = 'VENDOR_OWN_VIEW_PERM'
export const REP_OWN_VIEW_PERM = 'REP_OWN_VIEW_PERM'
export const ISO_OWN_VIEW_PERM = 'ISO_OWN_VIEW_PERM'
export const OVERALL_DEALS_MODULE_PERM = 'OVERALL_DEALS_MODULE_PERM'
export const FUNDED_DEALS_PERM = 'FUNDED_DEALS_PERM'
export const SUBMITTED_DEALS_PERM = 'SUBMITTED_DEALS_PERM'
export const IMPORT_MASS_LEGAL_FEES_PERM = 'IMPORT_MASS_LEGAL_FEES_PERM'
export const CREATE_REP_PERM = 'CREATE_REP_PERM'
export const DEAL_UPLOAD_DOCUMENTS_PERM = 'DEAL_UPLOAD_DOCUMENTS_PERM'
export const DEAL_NEW_PAYMENT_PERM = 'DEAL_NEW_PAYMENT_PERM'
export const DEAL_SCHEDULE_PAYMENT_PERM = 'DEAL_SCHEDULE_PAYMENT_PERM'
export const DEAL_REFUND_PERM = 'DEAL_REFUND_PERM'
export const DEAL_SET_TO_DEFAULT_PERM = 'DEAL_SET_TO_DEFAULT_PERM'
export const DEAL_DEACTIVATE_CRM_FEES_PERM = 'DEAL_DEACTIVATE_CRM_FEES_PERM'
export const DEAL_EDIT_PERM = 'DEAL_EDIT_PERM'
export const DEAL_DELETE_PERM = 'DEAL_DELELTE_PERM'
export const DEAL_EMAIL_PAY_HISTORY_PERM = 'DEAL_EMAIL_PAY_HISTORY_PERM'
export const DEAL_DOWNLOAD_PAY_HISTORY_PERM = 'DEAL_DOWNLOAD_PAY_HISTORY_PERM'
export const DEAL_EMAIL_PAYOFF_LETTER_PERM = 'DEAL_EMAIL_PAYOFF_LETTER_PERM'
export const DEAL_PREP_FUNDING_EMAIL_PERM = 'DEAL_PREP_FUNDING_EMAIL_PERM'
export const DEAL_READY_TO_FUND_PERM = 'DEAL_READY_TO_FUND_PERM'
export const DEAL_REFI_PERM = 'DEAL_REFI_PERM'
export const DEAL_CHARGE_OFF_PERM = 'DEAL_CHARGE_OFF_PERM'
export const DEAL_COMMENTS_LOG_PERM = 'DEAL_COMMENTS_LOG_PERM'
export const DEAL_AUDIT_LOG_PERM = 'DEAL_AUDIT_LOG_PERM'
export const DEAL_EXTEND_PERM = 'DEAL_EXTEND_PERM'
export const DEAL_CLAWBACK_PERM = 'DEAL_CLAWBACK_PERM'
export const DEAL_PAY_HISTORY_DETAILS_CONDENSED = 'DEAL_PAY_HISTORY_DETAILS_CONDENSED'
export const MY_DEALS_PERM = 'MY_DEALS_PERM'
export const BANK_MODULE_PERM = 'BANK_MODULE_PERM'
export const CREATE_INHOUSE_OUTHOUSE_PERM = 'CREATE_INHOUSE_OUTHOUSE_PERM'
export const ADD_ISO_PERM = 'ADD_ISO_PERM'
export const ADD_VENDOR_TO_DEAL = 'ADD_VENDOR_TO_DEAL'
export const EDIT_VENDOR_PERM = 'EDIT_VENDOR_PERM'
export const ADD_TRANSACTION_PERM = 'ADD_TRANSACTION_PERM'
export const ADD_WITHDRAWAL_PERM = 'ADD_WITHDRAWAL_PERM'
export const EDIT_ISO_PERM = 'EDIT_ISO_PERM'
export const EDIT_DEAL_PAYMENT = 'EDIT_DEAL_PAYMENT'
export const SEE_ISO_DEALS_PERM = 'SEE_ISO_DEALS_PERM'
export const SET_MANAGEMENT_FEE = 'SET_MANAGEMENT_FEE'
export const APPLY_COMMISSION_PERM = 'APPLY_COMMISSION_PERM'
export const APPLY_PSF_PERM = 'APPLY_PSF_PERM'
export const DELETE_USER_UPLOADED_DOCUMENTS = 'DELETE_USER_UPLOADED_DOCUMENTS'
export const UPLOAD_USER_PAGE_DOCS_PERM = 'UPLOAD_USER_PAGE_DOCS_PERM'
export const SENT_NOTIFICATIONS_PERM = 'SENT_NOTIFICATIONS_PERM'
export const HOLIDAYS_PERM = 'HOLIDAYS_PERM'
export const INTEGRATIONS_PERM = 'INTEGRATIONS_PERM'
export const MOVE_TO_DOCUSIGN = 'MOVE_TO_DOCUSIGN'

export const adminPermissions = [
  HOLIDAYS_PERM,
  INTEGRATIONS_PERM,
  ASSIGN_LENDERS_APPROVED_PERM,
  SENT_NOTIFICATIONS_PERM,
  UPLOAD_USER_PAGE_DOCS_PERM,
  DELETE_USER_UPLOADED_DOCUMENTS,
  APPLY_PSF_PERM,
  APPLY_COMMISSION_PERM,
  SEE_ISO_DEALS_PERM,
  EDIT_DEAL_PAYMENT,
  ADD_TRANSACTION_PERM,
  ADD_WITHDRAWAL_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  SYNDICATOR_CREATE_PERM,
  DEAL_READY_TO_FUND_PERM,
  DEAL_REFI_PERM,
  DEAL_EXTEND_PERM,
  DEAL_COMMENTS_LOG_PERM,
  DEAL_AUDIT_LOG_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
  DEAL_SCHEDULE_PAYMENT_PERM,
  DEAL_REFUND_PERM,
  DEAL_CHARGE_OFF_PERM,
  DEAL_NEW_PAYMENT_PERM,
  DEAL_SET_TO_DEFAULT_PERM,
  DEAL_DEACTIVATE_CRM_FEES_PERM,
  DEAL_EDIT_PERM,
  DEAL_DELETE_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_CLAWBACK_PERM,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  CLIENTS_MODULE_PERM,
  CLIENT_INVITE_PERM,
  DASHBOARD_MODULE_PERM,
  NOTIFICATIONS_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  NACHA_LIST_PERM,
  WITHDRAWAL_PERM,
  TRANSACTION_PERM,
  LEGAL_FEE_PERM,
  TODAY_PAYMENT_REPORT_PERM,
  ACH_MODULE_PERM,
  SYNDICATORS_MODULE_PERM,
  SYNDICATORS_VIEW_PERM,
  DEAL_MODULE_PERM,
  ISO_MODULE_PERM,
  REP_MODULE_PERM,
  VENDORS_MODULE_PERM,
  VENDORS_ADD_PERM,
  EDIT_VENDOR_PERM,
  FUNDING_QUES_PERM,
  WITHDRAWAL_QUES_PERM,
  USER_ROLES_MODULE_PERM,
  USER_MODULE_PERM,
  LATEST_DEALS_PERM,
  ASSIGN_LENDERS_PERM,
  CREATE_INHOUSE_COMPANY_PERM,
  CREATE_OUTHOUSE_COMPANY_PERM,
  REP_PERFORMANCE_PERM,
  IMPORT_MASS_LEGAL_FEES_PERM,
  CREATE_REP_PERM,
  NOTIFICATIONS_CREATE_WITH_ROLES_PERM,
  INHOUSE_COMPANY_MODULE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  BANK_MODULE_PERM,
  CREATE_INHOUSE_OUTHOUSE_PERM,
  ADD_ISO_PERM,
  ADD_VENDOR_TO_DEAL,
  EDIT_ISO_PERM,
  MOVE_TO_DOCUSIGN,
]

export const isoPermissions = [
  SEE_ISO_DEALS_PERM,
  DEAL_SCHEDULE_PAYMENT_PERM,
  OVERALL_DEALS_MODULE_PERM,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  CLIENTS_MODULE_PERM,
  CLIENT_INVITE_PERM,
  ISO_OWN_VIEW_PERM,
  ASSIGN_LENDERS_PERM,
  DEAL_MODULE_PERM,
  DEAL_COMMENTS_LOG_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
  DEAL_EDIT_PERM,
  NOTIFICATIONS_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  SENT_NOTIFICATIONS_PERM,
  CREATE_INHOUSE_OUTHOUSE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  CREATE_OUTHOUSE_COMPANY_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_READY_TO_FUND_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
]
export const repPermissions = [
  UPLOAD_USER_PAGE_DOCS_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
  REP_PERFORMANCE_PERM,
  OVERALL_DEALS_MODULE_PERM,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  CLIENTS_MODULE_PERM,
  CLIENT_INVITE_PERM,
  ISO_MODULE_PERM,
  ASSIGN_LENDERS_PERM,
  DEAL_MODULE_PERM,
  DEAL_COMMENTS_LOG_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
  DEAL_EDIT_PERM,
  NOTIFICATIONS_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  SENT_NOTIFICATIONS_PERM,
  CREATE_INHOUSE_OUTHOUSE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  CREATE_OUTHOUSE_COMPANY_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_READY_TO_FUND_PERM,
  ADD_ISO_PERM,
  REP_OWN_VIEW_PERM,
  DEAL_REFI_PERM,
]
export const syndicatorPermissions = [
  REQUEST_FUNDER_INFO_PERM,
  APPROVE_FUNDERS_LIMIT_PERM,
  PORTFOLIO_SUMMARY_PERM,
  NOTIFICATIONS_VIEW_PERM,
  SYNDICATOR_OWN_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  SENT_NOTIFICATIONS_PERM,
]
export const collectorPermissions = [
  NOTIFICATIONS_VIEW_PERM,
  LEGAL_FEE_PERM,
  MY_DEALS_PERM,
  DEAL_MODULE_PERM,
  DEAL_COMMENTS_LOG_PERM,
  VENDOR_OWN_VIEW_PERM,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  CLIENTS_MODULE_PERM,
]
export const funderPermissions = [
  UPLOAD_USER_PAGE_DOCS_PERM,
  ASSIGN_LENDERS_APPROVED_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  DEAL_SET_TO_DEFAULT_PERM,
  DEAL_REFUND_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_REFI_PERM,
  DEAL_CHARGE_OFF_PERM,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
  NOTIFICATIONS_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  SENT_NOTIFICATIONS_PERM,
  DEAL_SCHEDULE_PAYMENT_PERM,
  DEAL_CLAWBACK_PERM,
  ADD_VENDOR_TO_DEAL,
  REQUEST_FUNDER_INFO_PERM,
  APPROVE_FUNDERS_LIMIT_PERM,
  PORTFOLIO_SUMMARY_PERM,
  SYNDICATOR_OWN_VIEW_PERM,
  REP_PERFORMANCE_PERM,
  OVERALL_DEALS_MODULE_PERM,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  CLIENTS_MODULE_PERM,
  CLIENT_INVITE_PERM,
  ISO_MODULE_PERM,
  ASSIGN_LENDERS_PERM,
  DEAL_MODULE_PERM,
  DEAL_COMMENTS_LOG_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
  DEAL_EDIT_PERM,
  CREATE_INHOUSE_OUTHOUSE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  CREATE_OUTHOUSE_COMPANY_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_READY_TO_FUND_PERM,
  ADD_ISO_PERM,
  FUNDING_QUES_PERM,
  REP_OWN_VIEW_PERM,
  SET_MANAGEMENT_FEE,
  MOVE_TO_DOCUSIGN,
]
export const assistantPermissions = [NOTIFICATIONS_VIEW_PERM]

export const clientPermissions = [
  PERMISSION_CREATE_PERSONAL_CLIENT,
  CREATE_CLIENT_DEAL,
  DEAL_MODULE_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
  DEAL_EDIT_PERM,
  NOTIFICATIONS_VIEW_PERM,
]
