import { AxiosResponse } from 'axios'
import {
  API_CREATE_SYNDICATOR,
  API_GET_SYNDICATOR,
  API_GET_SYNDICATORS,
  API_UPDATE_SYNDICATOR,
  API_SYNDICATOR_LIGHT,
  API_DELETE_SYNDICATOR_DOCUMENT,
  API_CONNECT_SYNDICATOR_DOCUMENTS_TO_SYNDICATOR,
  API_SYNDICATOR_REPORT,
  API_REQUEST_INFO,
  API_REQUEST_COOPERATION,
  API_SYNDICATOR_SET_LIMIT,
  API_GET_PORTFOLIO_SUMMARY,
  API_GET_PORTFOLIO_SUMMARY_TOTAL,
  API_GET_SYNDICATED_DEAL_STATS,
  API_GET_SYNDICATED_DEALS,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { IPagination } from 'typescript/interfaces/pagination'
import {
  IConnectSyndicatorFileLinksToIsoParams,
  IGetSyndicatorParams,
  ILightSyndicator,
  IPortfolioSummaryItem,
  IPortfolioSummaryParams,
  IPortfolioSummaryTotal,
  ISyndicatedDealListItem,
  ISyndicatedDealsParams,
  ISyndicatedDealStatsItem,
  ISyndicatedDealStatsParams,
  ISyndicatorDetailEntity,
  ISyndicatorReportResponse,
  SyndicatorDealsSortingFields,
  SyndicatorForm,
  SyndicatorListItem,
  SyndicatorSortFields,
} from 'typescript/interfaces/syndicators'
import removeEmptyAttributes from 'utils/removeEmptyAttributes'
import { storageInstance } from 'services/axios/storageInstance'
import { IDealDocumentConnectParams, IDealDocumentConnectParamsResponse } from 'typescript/interfaces/deals'
import { getDownloadUrl, uploadToS3 } from 'services/storage'
import { ISetLimitModalData } from 'typescript/interfaces/rep'
import { generatePath } from 'react-router-dom'

export const createSyndicator = (values: SyndicatorForm) =>
  privateInstance.post<SyndicatorForm, AxiosResponse<SyndicatorListItem>>(API_CREATE_SYNDICATOR, values)

export const editSyndicator = (values: SyndicatorForm) =>
  privateInstance.put<SyndicatorForm, AxiosResponse<SyndicatorForm>>(API_UPDATE_SYNDICATOR, values)

export const getSyndicators = (params: IGetSyndicatorParams) => {
  const sParams = new URLSearchParams(removeEmptyAttributes(params) as Record<string, string>)
  return privateInstance.get<SyndicatorForm, AxiosResponse<SyndicatorListItem[]>>(`${API_GET_SYNDICATORS}?${sParams.toString()}`)
}
export const getSyndicator = (id: string) => {
  return privateInstance.get<SyndicatorForm, AxiosResponse<ISyndicatorDetailEntity>>(API_GET_SYNDICATOR.replace(':id', id))
}

export const getSyndicatorsLight = (params: IGetSyndicatorParams) =>
  privateInstance.get<IPagination<SyndicatorSortFields>, AxiosResponse<ILightSyndicator[]>>(API_SYNDICATOR_LIGHT, {
    params: params,
  })

export const getSyndicatorReport = (params: IPagination<SyndicatorDealsSortingFields> & { SyndicatorId: string }) =>
  privateInstance.get<IPagination<SyndicatorSortFields>, AxiosResponse<ISyndicatorReportResponse>>(API_SYNDICATOR_REPORT, {
    params,
  })

export const deleteFileFromSyndicator = (id: number) => storageInstance.delete(API_DELETE_SYNDICATOR_DOCUMENT.replace(':id', id.toString()))

export const connectUploadedUrlToSyndicator = async (body: IConnectSyndicatorFileLinksToIsoParams) =>
  storageInstance.post<IDealDocumentConnectParams, AxiosResponse<IDealDocumentConnectParamsResponse>>(
    API_CONNECT_SYNDICATOR_DOCUMENTS_TO_SYNDICATOR,
    body,
  )

export const uploadSyndicatorDocuments = async (documents: File[], syndicatorId: number | string) => {
  const links = await Promise.all(
    documents.map((doc) => {
      return getDownloadUrl({
        DocumentName: doc.name,
        DocumentDestination: 'Syndicator',
        DestinationObjectId: syndicatorId,
      })
    }),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadToS3(documents[i], link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map((uploadedLink, i) => {
      return connectUploadedUrlToSyndicator({
        documentUrl: links[i].data.split('?')[0],
        description: documents[i].name,
        syndicatorId,
      })
    }),
  )

  return connectedUploadedUrls
}

export const requestInfo = (body: { representativeId: number }) => {
  return privateInstance.post<{ representativeId: number }, AxiosResponse<null>>(API_REQUEST_INFO, body)
}

export const requestCooperation = (body: { representativeId: number }) => {
  return privateInstance.put<{ representativeId: number }, AxiosResponse<null>>(API_REQUEST_COOPERATION, body)
}

export const setLimit = (body: ISetLimitModalData) => {
  return privateInstance.put<ISetLimitModalData, AxiosResponse<null>>(API_SYNDICATOR_SET_LIMIT, {
    ...body,
    limit: +body.limit!,
  })
}

export const getPortfolioSummary = (params: IPortfolioSummaryParams) => {
  return privateInstance.get<IPortfolioSummaryParams, AxiosResponse<IPortfolioSummaryItem[]>>(API_GET_PORTFOLIO_SUMMARY, {
    params: params,
  })
}

export const getPortfolioSummaryTotal = () => privateInstance.get<void, AxiosResponse<IPortfolioSummaryTotal>>(API_GET_PORTFOLIO_SUMMARY_TOTAL)

export const getSyndicatedDeals = (params: ISyndicatedDealsParams) => {
  return privateInstance.get<ISyndicatedDealsParams, AxiosResponse<ISyndicatedDealListItem[]>>(API_GET_SYNDICATED_DEALS, {
    params: params,
  })
}

export const getSyndicatedDealStats = (params: ISyndicatedDealStatsParams) => {
  return privateInstance.get<string, AxiosResponse<ISyndicatedDealStatsItem[]>>(generatePath(API_GET_SYNDICATED_DEAL_STATS, { id: params.DealId }), {
    params,
  })
}
