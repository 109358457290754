import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { SYNDICATOR_INFO_URL, UPLOAD_USER_PAGE_DOCS } from 'constants/routes'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import ReactInputMask from 'react-input-mask'
import { useMutation, useQuery } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { createSyndicator, editSyndicator, getSyndicator } from 'services/syndicators'
import { SyndicatorForm } from 'typescript/interfaces/syndicators'
import RequiredOption from 'UI/Select/RequiredOption'
import { AxiosError } from 'axios'
import { useSyndicatorFormStyles } from 'components/AddEditSyndicatorForm/style'
import { userPageInitValues } from 'typescript/interfaces/users'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useStates } from 'hooks/useStates'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { schemaAddEditSyndicators } from './schema'

const AddEditSyndicatorForm = () => {
  const { id } = useParams<{ id?: string }>()
  const { push, goBack } = useHistory()
  const snack = useSnackbar()
  const s = useSyndicatorFormStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { data: statesData } = useStates()
  useQuery(['syndicator', +id!], () => getSyndicator(id || ''), {
    enabled: !!id,
    onSuccess(res) {
      const {
        businessName,
        state,
        city,
        street,
        federalTaxId,
        zipCode,
        firstName,
        lastName,
        phone,
        fax,
        mobile,
        email,
        userPage,
        nameOnCheck,
        label,
        managementFee,
      } = res.data
      setValues({
        firstName,
        lastName,
        phone,
        fax,
        mobile,
        email,
        userPage,
        nameOnCheck,
        label,
        businessName,
        stateId: state.id,
        city,
        street,
        federalTaxId,
        zipCode,
        managementFee,
      })
    },
  })

  const handleError = useCallback((err: AxiosError) => {
    if (err.response && err.response.data.description === 'SYNDICATOR_FOR_COMPANY_EXISTS')
      snack.enqueueSnackbar('Syndicator with the current company already exists')
    else if (err.response && err.response.data.description === 'SYNDICATOR_FOR_USER_EXISTS')
      snack.enqueueSnackbar('Syndicator with the current email already exists')
    else if (err.response && err.response.data.description === 'ANOTHER_COMPANY_ATTACHED_TO_USER')
      snack.enqueueSnackbar('User with the current email already exists')
    else if (err.response?.data.description === 'BANK_ACCOUNT_USED') snack.enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
    else snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
  }, [])

  const [mutateCreateSyndicator, { isLoading: isCreateLoading }] = useMutation(createSyndicator, {
    onSuccess(res) {
      push(
        `${generatePath(UPLOAD_USER_PAGE_DOCS, {
          type: 'syndicator',
          id: res.data.id,
        })}?type=create`,
      )
      snack.enqueueSnackbar(<Typography>Successfully created syndicator</Typography>)
    },
    onError: handleError,
  })
  const [mutateUpdateCompany, { isLoading: isUpdateLoading }] = useMutation(editSyndicator, {
    onSuccess(res) {
      push(SYNDICATOR_INFO_URL.replace(':id', `${id}`))
      snack.enqueueSnackbar(<Typography>Successfully updated syndicator</Typography>)
    },
    onError: handleError,
  })

  const { values, setValues, handleChange, handleSubmit, errors, touched } = useFormik<SyndicatorForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      fax: '',
      mobile: '',
      email: '',
      nameOnCheck: '',
      userPage: userPageInitValues,
      label: '',
      businessName: '',
      city: '',
      street: '',
      zipCode: '',
      federalTaxId: '',
      stateId: '',
      managementFee: '',
    },
    validationSchema: schemaAddEditSyndicators(Boolean(id)),
    onSubmit: (formValues) => {
      if (id) {
        mutateUpdateCompany({ ...formValues, id: +id })
      } else {
        mutateCreateSyndicator(formValues)
      }
    },
  })

  return (
    <Grid
      className={additionalStyle.wrapper}
      container
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit()
      }}
      spacing={8}
    >
      <Grid item xs={6}>
        <Paper className={s.paper}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography className={s.heading}>{id ? 'Edit Syndicator' : 'Add New Syndicator'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={values.businessName}
                onChange={handleChange}
                error={Boolean(errors.businessName && touched.businessName)}
                helperText={touched.businessName && errors.businessName}
                name="businessName"
                label={<RequiredOption label="Business Name" />}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                fullWidth
                label={<RequiredOption label="First Name" />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                fullWidth
                label={<RequiredOption label="Last name" />}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
                {(props: any) => (
                  <TextField
                    variant="outlined"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    label={<RequiredOption label="Phone" />}
                    {...props}
                  />
                )}
              </ReactInputMask>
            </Grid>
            <Grid item xs={6}>
              <ReactInputMask name="mobile" mask="(999)-999-9999" value={values.mobile} onChange={handleChange}>
                {(props: any) => (
                  <TextField
                    variant="outlined"
                    error={!!touched.mobile && !!errors.mobile}
                    helperText={touched.mobile && errors.mobile}
                    fullWidth
                    label="Mobile"
                    {...props}
                  />
                )}
              </ReactInputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={!!touched.fax && !!errors.fax}
                helperText={touched.fax && errors.fax}
                value={values.fax}
                name="fax"
                onChange={handleChange}
                fullWidth
                label="Fax"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={Boolean(id)}
                variant="outlined"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                value={values.email}
                name="email"
                onChange={handleChange}
                label={<RequiredOption label="Email" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={!!touched.label && !!errors.label}
                helperText={touched.label && errors.label}
                value={values.label}
                name="label"
                onChange={handleChange}
                fullWidth
                label="Label"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!touched.street && !!errors.street}
                variant="outlined"
                helperText={touched.street && errors.street}
                value={values.street}
                name="street"
                onChange={handleChange}
                label={<RequiredOption label="Street" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!touched.city && !!errors.city}
                variant="outlined"
                helperText={touched.city && errors.city}
                value={values.city}
                name="city"
                onChange={handleChange}
                label={<RequiredOption label="City" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.stateId && errors.stateId}
                error={!!touched.stateId && !!errors.stateId}
                value={values.stateId}
                name="stateId"
                onChange={handleChange}
                label={<RequiredOption label="State" />}
                fullWidth
              >
                {statesData?.data.map((state) => (
                  <MenuItem value={state.id}>{state.name}</MenuItem>
                ))}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!!touched.zipCode && !!errors.zipCode}
                variant="outlined"
                helperText={touched.zipCode && errors.zipCode}
                value={values.zipCode}
                name="zipCode"
                onChange={handleChange}
                label={<RequiredOption label="Zip Code" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReactInputMask name="federalTaxId" mask="99-9999999" value={values.federalTaxId} onChange={handleChange}>
                {(props: any) => (
                  <TextField
                    {...props}
                    error={!!touched.federalTaxId && !!errors.federalTaxId}
                    variant="outlined"
                    helperText={touched.federalTaxId && errors.federalTaxId}
                    label={<RequiredOption label="Federal Tax ID" />}
                    fullWidth
                  />
                )}
              </ReactInputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Management Fee %" />}
                name="managementFee"
                value={values.managementFee}
                onChange={handleChange}
                error={Boolean(touched.managementFee && errors.managementFee)}
                helperText={touched.managementFee && errors.managementFee}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={s.paper}>
          <Box className={s.fullHeight} display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Typography variant="h2" color="textSecondary">
                Bank Information
              </Typography>
              <Box mt="3rem">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      value={values.userPage.bankName}
                      name="userPage.bankName"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      label={<RequiredOption label="Bank Name" />}
                      error={Boolean(touched.userPage?.bankName && errors.userPage?.bankName)}
                      helperText={touched.userPage?.bankName && errors.userPage?.bankName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactInputMask
                      name="userPage.bankRouting"
                      mask="999999999"
                      maskPlaceholder={null}
                      value={values.userPage.bankRouting}
                      onChange={handleChange}
                    >
                      {(props: any) => (
                        <TextField
                          variant="outlined"
                          error={!!touched.userPage?.bankRouting && !!errors.userPage?.bankRouting}
                          helperText={touched.userPage?.bankRouting && errors.userPage?.bankRouting}
                          fullWidth
                          label={<RequiredOption label="Bank Routing #" />}
                          {...props}
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt="3rem">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    value={values.userPage.bankAccount}
                    name="userPage.bankAccount"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label={<RequiredOption label="Bank Account #" />}
                    error={Boolean(touched.userPage?.bankAccount && errors.userPage?.bankAccount)}
                    helperText={touched.userPage?.bankAccount && errors.userPage?.bankAccount}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={Boolean(id)}
                    value={values.userPage.availableBalance}
                    name="userPage.availableBalance"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label={<RequiredOption label="Starting Balance" />}
                    error={Boolean(touched.userPage?.availableBalance && errors.userPage?.availableBalance)}
                    helperText={touched.userPage?.availableBalance && errors.userPage?.availableBalance}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt="3rem">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    value={values.nameOnCheck}
                    name="nameOnCheck"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Name on Voided Check"
                    error={Boolean(touched.nameOnCheck && errors.nameOnCheck)}
                    helperText={touched.nameOnCheck && errors.nameOnCheck}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt="auto" pt="2rem">
              <Button color="secondary" variant="contained" onClick={() => goBack()} className={s.backButton}>
                Cancel
              </Button>
              <Button disabled={isCreateLoading || isUpdateLoading} color="primary" variant="contained" type="submit">
                Complete
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AddEditSyndicatorForm
