import * as yup from 'yup'
import { BANK_COMPANY_MUST_BE_AT_MOST_NINE_SYMBOLS, INVALID_FEDERAL_TAX_ID, REQUIRED_FIELD, ZIP_CODE_FORMAT_ERROR } from 'constants/errors'
import { zipCodeReg } from 'constants/regExp'

export const schemaAddEditCompany = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  description: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  street: yup.string().required(REQUIRED_FIELD),
  stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  federalTaxId: yup
    .string()
    .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
    .required(REQUIRED_FIELD),
  zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  bankCompanyId: yup.string().max(9, BANK_COMPANY_MUST_BE_AT_MOST_NINE_SYMBOLS),
  bankAccountName: yup.string().nullable(),
})
