import React, { FC, useCallback, useState } from 'react'
import { useFormik } from 'formik'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FileDragNDrop from 'components/FileDragNDrop'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useMutation, useQuery } from 'react-query'
import { getClientById, deleteFileFromClient } from 'services/clients'
import { IClientDocumentConnectParamsResponse, IUploadedClientCategoriesKeys, IUploadedClientDocuments } from 'typescript/interfaces/deals'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { removeAtIndex } from 'utils/arrayHelpers'
import { IInitClientDocsValues, IRemoveClientFileParams } from 'typescript/interfaces/clients'
import { AxiosError } from 'axios'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { documentsClientValidationSchema } from 'components/ClientForms/DocumentsForm/schema'

interface IDocumentForm {
  submit: {
    label: string
    handler: (values: IInitClientDocsValues) => any
  }
  loading?: boolean
  back: {
    label: string
    handler: () => any
  }
  initialValues?: IInitClientDocsValues
}

const initValues: IInitClientDocsValues = {
  documents: {
    VoidedCheck: [],
    Application: [],
    DriverLicense: [],
    BankStatements: [],
    CreditProcessingStatements: [],
    W9: [],
    Optional: [],
  },
  uploadedDocuments: {
    VoidedCheck: [],
    Application: [],
    DriverLicense: [],
    BankStatements: [],
    CreditProcessingStatements: [],
    W9: [],
    Optional: [],
  },
}

const DocumentsForm: FC<IDocumentForm> = ({ submit, back, initialValues = initValues, loading }) => {
  const [deleteFile, setDeleteFile] = useState<{
    file: IClientDocumentConnectParamsResponse | undefined
    open: boolean
  }>({
    file: undefined,
    open: false,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  useQuery(['client', id], () => getClientById(id), {
    onSuccess(res) {
      const {
        data: { documents },
      } = res

      const documentsToSet: IUploadedClientDocuments = {
        VoidedCheck: [],
        Application: [],
        DriverLicense: [],
        BankStatements: [],
        CreditProcessingStatements: [],
        W9: [],
        Optional: [],
      }

      documents.forEach((doc) => {
        documentsToSet[doc.documentType].push(doc)
      })

      setFieldValue('uploadedDocuments', documentsToSet)
    },
    onError() {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
    enabled: id,
  })

  const { handleSubmit, setFieldValue, values, touched, errors } = useFormik({
    initialValues: initialValues,
    onSubmit: submit.handler,
    validationSchema: documentsClientValidationSchema,
  })

  const handleDrop = useCallback(
    (files: File[], name: IUploadedClientCategoriesKeys) => {
      let filesToAdd: File[] = []
      // if (files.length > 0 && !files.some((f) => f.name.endsWith('.pdf'))) {
      //   enqueueSnackbar("Files with inappropriate format can't be added")
      //   filesToAdd = files.filter((f) => f.name.endsWith('.pdf'))
      // } else
      if (files.length > 0 && files.some((f) => f.size > 50000000)) {
        enqueueSnackbar('Files must be no more than 50MB.')
        filesToAdd = files.filter((f) => f.size <= 50000000)
      } else {
        filesToAdd = files
      }
      setFieldValue(`documents.${name}`, [...values.documents[name], ...filesToAdd])
    },
    [values],
  )

  /** Remove local file **/
  const handleRemove = useCallback(
    (index: number, name: IUploadedClientCategoriesKeys) => {
      setFieldValue(`documents.${name}`, removeAtIndex(values.documents[name], index))
    },
    [values],
  )

  /** Remove file from S3 storage **/
  const [mutateRemove, { isLoading: isLoadingDeleteFile }] = useMutation(
    ({ id: docId, name }: IRemoveClientFileParams) => deleteFileFromClient(docId),
    {
      onSuccess(_, variables) {
        enqueueSnackbar(<Typography>Successfully deleted document</Typography>)
        setFieldValue(
          `uploadedDocuments.${variables.name}`,
          values.uploadedDocuments[variables.name].filter((doc) => `${doc.id}` !== variables.id),
        )
        setDeleteFile({
          file: undefined,
          open: false,
        })
      },
      onError: (err: AxiosError) => {
        setDeleteFile({
          file: undefined,
          open: false,
        })
        if (err.response && err.response.data.description === 'CANT_DELETE_LAST_DOCUMENT')
          enqueueSnackbar('The last file of this type cannot be deleted')
      },
    },
  )

  const handleRemoveUploaded = useCallback(
    (documentId: number, name: IUploadedClientCategoriesKeys) => {
      const deletedFile = values.uploadedDocuments[name].find((doc) => doc.id === documentId)
      setDeleteFile({
        file: deletedFile,
        open: true,
      })
    },
    [setFieldValue, mutateRemove, values],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography variant="h3" color="textSecondary">
          Required Documents
        </Typography>
      </Box>
      <Box mt="1.5rem">
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <FileDragNDrop
              title="Voided check"
              name="VoidedCheck"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.VoidedCheck}
              uploaded={Boolean(values.uploadedDocuments.VoidedCheck.length)}
              files={values.documents.VoidedCheck}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.VoidedCheck && errors.documents?.VoidedCheck)}
            />
          </Grid>
          <Grid item xs={3}>
            <FileDragNDrop
              title="Application"
              name="Application"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.Application}
              uploaded={Boolean(values.uploadedDocuments.Application.length)}
              files={values.documents.Application}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.Application && errors.documents?.Application)}
            />
          </Grid>
          <Grid item xs={3}>
            <FileDragNDrop
              title="Driver's License"
              name="DriverLicense"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.DriverLicense}
              uploaded={Boolean(values.uploadedDocuments.DriverLicense.length)}
              files={values.documents.DriverLicense}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.DriverLicense && errors.documents?.DriverLicense)}
            />
          </Grid>
          <Grid item xs={3}>
            <FileDragNDrop
              title="Bank Statements"
              name="BankStatements"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.BankStatements}
              uploaded={Boolean(values.uploadedDocuments.BankStatements.length)}
              files={values.documents.BankStatements}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.BankStatements && errors.documents?.BankStatements)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem">
        <Typography variant="h3" color="textSecondary">
          Optional Documents
        </Typography>
      </Box>
      <Box mt="1.5rem">
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <FileDragNDrop
              maxFiles={1}
              title="Optional"
              name="Optional"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.Optional}
              uploaded={Boolean(values.uploadedDocuments.Optional.length)}
              files={values.documents.Optional}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
            />
          </Grid>
          <Grid item xs={3}>
            <FileDragNDrop
              title="W9"
              name="W9"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.W9}
              uploaded={Boolean(values.uploadedDocuments.W9.length)}
              files={values.documents.W9}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.W9 && errors.documents?.W9)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem" display="flex" alignItems="center">
        <Box mr={5}>
          <Button color="secondary" onClick={() => back.handler()} variant="contained" startIcon={<ArrowLeftIcon />}>
            {back.label}
          </Button>
        </Box>
        <Button loading={loading} color="primary" variant="contained" type="submit">
          {submit.label}
        </Button>
      </Box>
      <ModalComponent open={deleteFile.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteFile}
          text="Are you sure you want to delete file?"
          handleCancel={() =>
            setDeleteFile({
              file: undefined,
              open: false,
            })
          }
          handleConfirm={() => {
            mutateRemove({
              id: deleteFile.file?.id.toString() || '',
              name: deleteFile.file?.documentType || 'VoidedCheck',
            })
          }}
        />
      </ModalComponent>
    </form>
  )
}

export default DocumentsForm
