import React, { FC } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import { IClientEntity } from 'typescript/interfaces/clients'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStates } from 'hooks/useStates'
import { convertEmptyField, convertDate, convertToPriceFormat } from 'utils/formatters'

interface IGeneralInfoProps {
  data: IClientEntity | undefined
  loading: boolean
}

const useGeneralInfoStyle = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}))

const GeneralInfo: FC<IGeneralInfoProps> = ({ data, loading }) => {
  const classes = useGeneralInfoStyle()
  const { data: dataStates } = useStates()
  const physState = dataStates && data ? dataStates.data.find((s) => s.id === data.phisicalStateId) : undefined
  return (
    <Paper elevation={0} className={classes.paper}>
      {loading || !data ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box px={12} py={8}>
            <Typography variant="h3" color="textSecondary">
              General Information
            </Typography>
          </Box>
          <Box px={12} pb={8}>
            <Grid container>
              <Grid item container spacing={2} xs={6}>
                <Grid item xs={6}>
                  <Typography>Client Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.clientType)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Joined</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(data.joinedOn)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>REP</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.repName)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Industry</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.industry.name)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Business Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.businessName)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Doing Business As</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.doingBusinessAs)}</Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={6}>
                <Grid item xs={6}>
                  <Typography>Business Start Day</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(data.businessStartDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Gross Annual Income</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertToPriceFormat(data.grossAnnualIncome)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>No. Of Owners</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{data.owners.length}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Federal Tax ID</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.federalTaxId)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Expected Grade</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.expectedGrade)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box px={12} py={8}>
            <Box pb={8}>
              <Grid container>
                <Grid item container spacing={2} xs={6}>
                  <Grid item xs={12}>
                    <Typography variant="h3" color="textSecondary">
                      Contact Information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={6}>
                  <Grid item xs={12}>
                    <Typography variant="h3" color="textSecondary">
                      Mailing Address
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid container alignItems="baseline">
              <Grid item container spacing={2} xs={6}>
                <Grid item xs={6}>
                  <Typography>Phone</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.phone)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Fax</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.fax)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.email)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Website</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.webSite)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Street</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.street)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>City</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.city)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>State</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{physState ? physState.name : 'n/a'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Zip Code</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.zipCode)}</Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={6}>
                <Grid item xs={6}>
                  <Typography>Mailling Street</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.mailingStreet)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Mailing City</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.mailingCity)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Mailing State</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.mailingState?.name)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Mailing Zip Code</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.mailingZipCode)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Landlord / Mortgage Co.</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.landlordOrMortgageCo)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Landlord Contact Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.landlordContactName)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Business Entity</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.entityType)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Landlord Phone</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.landlordPhone)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Landlord Fax</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(data.landlordFax)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Landlord Expiration</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(data.leaseExpiration)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Rent / Mortgage Payment</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertToPriceFormat(data.rentOrMortgagePayment)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Paper>
  )
}

export default GeneralInfo
