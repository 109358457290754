import React, { FC, useCallback, useContext } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import ClientInfoForm from 'components/ClientForms/ClientInfoForm'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { editClient, getClientById } from 'services/clients'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { VIEW_CLIENT_URL } from 'constants/routes'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from 'contexts/userContext'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem',
  },
}))

const EditClientContainer: FC = () => {
  const { state } = useContext(UserContext)
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { goBack, push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const nextUrl = params.get('nextUrl')

  const { data, isLoading } = useQuery(['client', id], () => getClientById(id), {
    retry: false,
    cacheTime: 0,
  })

  const [editClientMut, { isLoading: isLoadingEdit }] = useMutation(editClient, {
    onSuccess: () => {
      if (nextUrl) push(nextUrl)
      else push(VIEW_CLIENT_URL.replace(':id(\\d+)', id))
      enqueueSnackbar('Client successfully edited')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values) => {
      const copyValues = {
        ...values,
        grossAnnualIncome: values.grossAnnualIncome || null,
      }
      delete copyValues.bankAccount
      editClientMut({
        ...copyValues,
        id,
      })
    },
    [editClientMut, id],
  )

  return (
    <>
      <Box display="flex" alignItems="center">
        {!state.roles.isClient && (
          <Box mr="1rem">
            <IconButton color="inherit" onClick={goBack}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        <Typography variant="h1" color="textSecondary">
          Edit Client #{data?.data.id}
        </Typography>
      </Box>
      <Box className={additionalStyle.wrapper} mt="2rem">
        <Paper className={s.paper} elevation={0}>
          {isLoading || !data ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <ClientInfoForm
              loading={isLoadingEdit}
              initialValues={{
                bankAccount: {
                  bankAccount: '',
                  bankRouting: '',
                  availableBalance: '',
                  bankAccountType: '',
                  bankName: '',
                },
                clientType: data.data.clientType,
                businessName: data.data.businessName,
                entityType: data.data.entityType,
                doingBusinessAs: data.data.doingBusinessAs,
                businessStartDate: data.data.businessStartDate.toString(),
                grossAnnualIncome: data.data.grossAnnualIncome.toString(),
                federalTaxId: data.data.federalTaxId,
                expectedGrade: data.data.expectedGrade,
                phone: data.data.phone,
                fax: data.data.fax,
                email: data.data.email,
                webSite: data.data.webSite,
                isCrossCollateralize: data.data.isCrossCollateralize,
                industryId: data.data.industryId.toString(),
                phisicalStateId: data.data.phisicalStateId.toString(),
                city: data.data.city,
                street: data.data.street,
                zipCode: data.data.zipCode,
                mailingStateId: data.data.mailingStateId ? data.data.mailingStateId.toString() : null,
                mailingCity: data.data.mailingCity,
                mailingStreet: data.data.mailingStreet,
                mailingZipCode: data.data.mailingZipCode,
                landlordOrMortgageCo: data.data.landlordOrMortgageCo,
                landlordContactName: data.data.landlordContactName,
                landlordPhone: data.data.landlordPhone,
                landlordFax: data.data.landlordFax,
                rentOrMortgagePayment: data.data.rentOrMortgagePayment,
                miCampId: data.data.miCampId,
                leaseExpiration: data.data.leaseExpiration ? data.data.leaseExpiration.toString() : null,
                isMailingAddressDifferent: data.data.isMailingAddressDifferent,
                additionalGuarantors: data.data.additionalGuarantors,
              }}
              back={{
                handler: goBack,
                label: 'Back',
              }}
              submit={{
                handler: handleSubmit,
                label: 'Save',
              }}
            />
          )}
        </Paper>
      </Box>
    </>
  )
}

export default EditClientContainer
