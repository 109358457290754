import React, { FC, useState, useCallback } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { ITableData } from 'typescript/interfaces/tableUi'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import { ReactComponent as SortingIcon } from 'assets/svg/SortingIcon.svg'
import { ReactComponent as SortAscIcon } from 'assets/svg/SortAscIcon.svg'
import { ReactComponent as SortDescIcon } from 'assets/svg/SortDescIcon.svg'
import { ReactComponent as LoadMoreIcon } from 'assets/svg/LoadMoreIcon.svg'
import { useTableComponentStyle } from 'UI/Table/style'
import cn from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'UI/Button/ButtonWithPreloader'
import { Waypoint } from 'react-waypoint'

export type TableComponentType<T extends object = any> = FC<ITableData<T>>

const TableComponent: TableComponentType = ({
  columns,
  data,
  order = '',
  orderBy = 'ASC',
  stickyHeader = false,
  Footer,
  loading,
  hasMore,
  handleGetMore,
  handleSortChange,
  lazy,
  tableClassName,
  tableContainerClassName,
  expandable,
  ExpandedComponent,
}) => {
  const classes = useTableComponentStyle({ stickyHeader: stickyHeader })
  const [sortingOption, setSortingOption] = useState({
    order: order,
    orderBy: orderBy,
  })

  const getSortIcon = useCallback(
    (field) => {
      if (sortingOption.order !== field) return <SortingIcon />
      if (sortingOption.order === field && sortingOption.orderBy === 'ASC') return <SortAscIcon />
      return <SortDescIcon />
    },
    [sortingOption],
  )

  const handleSort = useCallback(
    (c) => {
      setSortingOption((prevState) => {
        const sortOrder = c.sortField ?? c.field
        const sortOrderBy = sortOrder === prevState.order && prevState.orderBy === 'ASC' ? 'DESC' : 'ASC'
        handleSortChange?.(sortOrder, sortOrderBy)
        return {
          order: sortOrder,
          orderBy: sortOrderBy,
        }
      })
    },
    [sortingOption],
  )

  return (
    <TableContainer
      style={{
        maxHeight: 'inherit',
        position: 'relative',
        overflow: loading ? 'hidden' : 'auto',
      }}
      className={tableContainerClassName}
    >
      {loading && (
        <Box className={classes.loaderWrapper}>
          <CircularProgress color="primary" />
        </Box>
      )}
      <Table className={tableClassName} stickyHeader={stickyHeader}>
        <TableHead className={classes.headerTable}>
          <TableRow>
            {columns.map((c) => (
              <TableCell key={c.field.toString()} className={cn(c.headerCellClassName)}>
                {c.sorting ? (
                  <Box className={cn(classes.sortingBox)} onClick={() => handleSort(c)}>
                    {c.label}
                    <Box ml={1}>{getSortIcon(c.sortField ?? c.field)}</Box>
                  </Box>
                ) : typeof c.label === 'function' ? (
                  c.label(data)
                ) : (
                  c.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, index) => (
            <>
              <TableRow key={d.id ? d.id : d[columns[0].field ? columns[0].field : '']}>
                {columns.map((column) => (
                  <TableCell
                    className={cn(column.bodyCellClassName)}
                    style={
                      index === data.length - 1
                        ? {
                            ...column.cellStyle,
                            borderBottom: 'none',
                          }
                        : {
                            ...column.cellStyle,
                          }
                    }
                    key={column.field.toString()}
                  >
                    {column.render ? column.render(d) : d[column.field]}
                  </TableCell>
                ))}
              </TableRow>
              {expandable && d.tableOnlyRowExpanded && ExpandedComponent && <ExpandedComponent row={d} />}
            </>
          ))}
          {/*{lazy && hasMore && handleGetMore && data.length > 0 && <tr style={{ height: 1, width: '100%' }} ref={lazyLoadTarget} />}*/}
          {lazy && hasMore && handleGetMore && data.length > 0 && <Waypoint onEnter={handleGetMore} />}
        </TableBody>
        {Footer && <Footer />}
      </Table>
      {!lazy && hasMore && handleGetMore && data.length > 0 && (
        <Box width="100%" p={3} position="sticky" left={0}>
          <Button
            loading={loading}
            onClick={handleGetMore}
            variant="outlined"
            color="secondary"
            startIcon={<LoadMoreIcon />}
            fullWidth
            className={cn(classes.loadMoreButton)}
          >
            Show more
          </Button>
        </Box>
      )}
    </TableContainer>
  )
}

export default TableComponent
