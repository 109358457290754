import {
  API_CREATE_CLIENT,
  API_EDIT_CLIENT,
  API_GET_CLIENTS_LIST,
  API_GET_CLIENT_BY_ID,
  API_CREATE_OWNER,
  API_EDIT_OWNER,
  API_DELETE_OWNER,
  API_GET_CLIENTS_LIGHT,
  API_DELETE_CLIENT_DOCUMENT,
  API_GET_UPLOAD_URL,
  API_CONNECT_CLIENT_DOCUMENTS_TO_CLIENT,
  API_INVITE_CLIENT,
  API_GET_CLIENTS_BY_USER,
  API_GET_CLIENT_BANK_ACCOUNTS,
  API_CREATE_CLIENT_BANK_ACCOUNT,
  API_EDIT_CLIENT_BANK_ACCOUNT,
  API_DELETE_CLIENT_BANK_ACCOUNT,
  API_SET_MAIN_CLIENT_BANK_ACCOUNT,
  API_CLIENTS_ME,
  API_CLIENTS_EQUIFAX,
  API_CLIENTS_EXPERIAN,
  API_CLIENTS_PRBC,
  API_CLIENTS_ENHANCE_SEARCH,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import {
  IClientInitValue,
  IClientEntity,
  SortFieldsClients,
  IClientListItem,
  IClientListItemLight,
  IClientsListParams,
  IClientUploadLinkParams,
  IClientDocumentConnectParams,
  IInviteClientInitValues,
  IInviteClientResponse,
  ILightClientListParams,
  IGetClientBankAccountsParams,
  IBankAccountClient,
  ICreateEditBankAccountClientValues,
  IDeleteClientBankAccountParams,
  ISetMainBankAccountParams,
} from 'typescript/interfaces/clients'
import { IOwnerInitValue, IOwnerEntity } from 'typescript/interfaces/owners'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import { storageInstance } from 'services/axios/storageInstance'
import { IDealDocumentConnectParams, IDealDocumentConnectParamsResponse, IUploadDocWithDocType } from 'typescript/interfaces/deals'
import { uploadDealDocumentToS3 } from 'services/deals'
import { generatePath } from 'react-router-dom'
import { ICreditReportForm, IPrbcReportForm } from 'typescript/interfaces/reports'

export const createClient = (values: IClientInitValue) =>
  privateInstance.post<IClientInitValue, AxiosResponse<IClientEntity>>(API_CREATE_CLIENT, values)

export const editClient = (values: IClientInitValue & { id: string }) =>
  privateInstance.put<IClientInitValue, AxiosResponse<IClientEntity>>(API_EDIT_CLIENT, values)

export const getClients = (params: IClientsListParams) =>
  privateInstance.get<IClientsListParams, AxiosResponse<Array<IClientListItem>>>(API_GET_CLIENTS_LIST, {
    params: params,
  })

export const getClientsLight = (params: ILightClientListParams) =>
  privateInstance.get<IPagination<SortFieldsClients>, AxiosResponse<Array<IClientListItemLight>>>(API_GET_CLIENTS_LIGHT, {
    params: params,
  })

export const getClientById = (id: string) =>
  privateInstance.get<{ id: string }, AxiosResponse<IClientEntity>>(API_GET_CLIENT_BY_ID.replace(':id', id))

export const createOwner = (values: IOwnerInitValue) => privateInstance.post<IOwnerInitValue, AxiosResponse<IOwnerEntity>>(API_CREATE_OWNER, values)

export const editOwner = (values: IOwnerInitValue & { id: string }) =>
  privateInstance.put<IOwnerInitValue & { id: string }, AxiosResponse<IOwnerEntity>>(API_EDIT_OWNER, values)

export const deleteOwner = (id: string) => privateInstance.delete<{ id: string }, AxiosResponse<boolean>>(API_DELETE_OWNER.replace(':id', id))

export const deleteFileFromClient = (id: string) => storageInstance.delete(API_DELETE_CLIENT_DOCUMENT.replace(':id', id))

export const getClientDownloadUrl = (params: IClientUploadLinkParams) =>
  storageInstance.get<string, AxiosResponse<string>>(API_GET_UPLOAD_URL, { params })

export const connectUploadedUrlToClient = async (body: IClientDocumentConnectParams) =>
  storageInstance.post<IDealDocumentConnectParams, AxiosResponse<IDealDocumentConnectParamsResponse>>(API_CONNECT_CLIENT_DOCUMENTS_TO_CLIENT, body)

export const uploadClientDocuments = async (documents: IUploadDocWithDocType[], clientId: number) => {
  const links = await Promise.all(
    documents.map((doc) => {
      return getClientDownloadUrl({
        DocumentName: doc.file.name,
        DocumentDestination: 'Client',
        DestinationObjectId: clientId,
      })
    }),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadDealDocumentToS3(documents[i].file, link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map(
      (uploadedLink, i) =>
        connectUploadedUrlToClient({
          documentUrl: links[i].data.split('?')[0],
          description: documents[i].file.name,
          clientId,
          documentType: documents[i].documentType,
        }),
      //eslint-disable-next-line
    ),
  )

  return connectedUploadedUrls
}

export const inviteClient = (values: IInviteClientInitValues) =>
  privateInstance.post<IInviteClientInitValues, AxiosResponse<IInviteClientResponse>>(API_INVITE_CLIENT, values)

export const getClientsByUserId = (id: string) =>
  privateInstance.get<{ id: string }, AxiosResponse<IClientListItem>>(
    generatePath(API_GET_CLIENTS_BY_USER, {
      id: id,
    }),
  )

export const getClientBankAccounts = (params: IGetClientBankAccountsParams) =>
  privateInstance.get<IGetClientBankAccountsParams, AxiosResponse<Array<IBankAccountClient>>>(API_GET_CLIENT_BANK_ACCOUNTS, {
    params: params,
  })

export const createClientBankAccount = (values: ICreateEditBankAccountClientValues) =>
  privateInstance.post<ICreateEditBankAccountClientValues, AxiosResponse<IBankAccountClient>>(API_CREATE_CLIENT_BANK_ACCOUNT, values)

export const editClientBankAccount = (values: ICreateEditBankAccountClientValues) =>
  privateInstance.put<ICreateEditBankAccountClientValues, AxiosResponse<IBankAccountClient>>(API_EDIT_CLIENT_BANK_ACCOUNT, values)

export const deleteClientBankAccount = (values: IDeleteClientBankAccountParams) =>
  privateInstance.delete<IDeleteClientBankAccountParams, AxiosResponse<boolean>>(
    generatePath(API_DELETE_CLIENT_BANK_ACCOUNT, {
      clientId: values.clientId,
      bankAccountId: values.bankAccountId,
    }),
  )

export const setMainClientBankAccount = (values: ISetMainBankAccountParams) =>
  privateInstance.put<ISetMainBankAccountParams, AxiosResponse<boolean>>(API_SET_MAIN_CLIENT_BANK_ACCOUNT, values)

export const getMeClient = () => privateInstance.get<unknown, AxiosResponse<IClientEntity>>(API_CLIENTS_ME)

export const getEquifaxReport = (values: ICreditReportForm) =>
  privateInstance.post<ICreditReportForm, AxiosResponse<boolean>>(API_CLIENTS_EQUIFAX, values, { responseType: 'blob' })

export const getExperianReport = (values: ICreditReportForm) =>
  privateInstance.post<ICreditReportForm, AxiosResponse<boolean>>(API_CLIENTS_EXPERIAN, values, { responseType: 'blob' })

export const getPrbcReport = (values: IPrbcReportForm) =>
  privateInstance.post<IPrbcReportForm, AxiosResponse<boolean>>(API_CLIENTS_PRBC, values, { responseType: 'blob' })

export const getEnhancedReport = (values: ICreditReportForm) =>
  privateInstance.post<ICreditReportForm, AxiosResponse<boolean>>(API_CLIENTS_ENHANCE_SEARCH, values, { responseType: 'blob' })
