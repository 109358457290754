import React, { useEffect, useState } from 'react'
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom'
import CompletingForm from 'components/InhouseDealForm/Completing'
import DealInformationForm from 'components/InhouseDealForm/DealInformation'
import DocumentsForm from 'components/InhouseDealForm/Documents'
import { INHOUSE_DEAL, NEW_DEAL_URL } from 'constants/routes'
import CustomStepper, { Step } from 'UI/Stepper/CustomStepper'
import AssignLendersPage from 'containers/Deal/AssignLenders'

const InhouseDeal = () => {
  const { step = '0', view, id } = useParams<{ step: string; view: string; id: string }>()
  const { push, location } = useHistory()

  const [availableSteps, setAvailableSteps] = useState(view === 'edit' ? [0, 1, 2, 3] : [0])

  useEffect(() => {
    if (view === 'new') {
      setAvailableSteps([0])
    }
  }, [view])

  const handleStepChange = (_: Step, s: number) => {
    push({
      pathname: generatePath(NEW_DEAL_URL, {
        id: id,
        view: view,
        type: 'inhouse',
        step: s + 1,
      }),
      search: location.search,
    })
  }

  const steps = [{ title: 'Deal Information' }, { title: 'Documents' }, { title: 'Lenders' }, { title: 'Completing' }]

  return (
    <div>
      <CustomStepper steps={steps} current={+step - 1} available={availableSteps} onChange={handleStepChange} />

      <Switch>
        <Route
          path={INHOUSE_DEAL.replace(':step?', '1')}
          render={(params: any) => <DealInformationForm {...params} changeStepsAvailable={setAvailableSteps} />}
        />
        <Route path={INHOUSE_DEAL.replace(':step?', '2')} component={DocumentsForm} />
        <Route path={INHOUSE_DEAL.replace(':step?', '3')} render={(props) => <AssignLendersPage {...props} dealActions />} />
        <Route path={INHOUSE_DEAL.replace(':step?', '4')} component={CompletingForm} />
      </Switch>
    </div>
  )
}

export default InhouseDeal
