import React, { FC, useCallback } from 'react'
import { useQuery, useMutation } from 'react-query'
import { getRepById, editRep } from 'services/rep'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import RepForm from 'components/RepForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { REP_INFO_URL } from 'constants/routes'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError } from 'axios'

const EditRepContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(['repById', id], () => getRepById(id), {
    retry: false,
    cacheTime: 0,
  })

  const [editRepMut, { isLoading: isLoadingEditRep }] = useMutation(editRep, {
    onSuccess: () => {
      enqueueSnackbar('Representative successfully edited')
      push(
        generatePath(REP_INFO_URL, {
          id,
        }),
      )
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'REP_FOR_COMPANY_EXISTS') enqueueSnackbar('REP with the current company already exists')
      else if (err.response && err.response.data.description === 'REP_FOR_USER_EXISTS') enqueueSnackbar('REP with the current email already exists')
      else if (err.response && err.response.data.descripiton === 'ANOTHER_COMPANY_ATTACHED_TO_USER')
        enqueueSnackbar('User with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values) => {
      editRepMut({
        ...values,
        id: id,
      })
    },
    [id],
  )

  return isLoading || !data ? (
    <Box width="100%" display="flex" justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <RepForm
      label={`Edit Rep ${data.data.businessName}`}
      submit={handleSubmit}
      loading={isLoadingEditRep}
      initValues={{
        nameOnCheck: data.data.nameOnCheck,
        email: data.data.email,
        phone: data.data.phone,
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        mobile: data.data.mobile,
        fax: data.data.fax,
        userPage: data.data.userPage,
        businessName: data.data.businessName,
        city: data.data.city,
        street: data.data.street,
        zipCode: data.data.zipCode,
        federalTaxId: data.data.federalTaxId,
        stateId: data.data.state.id,
      }}
    />
  )
}

export default EditRepContainer
